import {_resource} from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("bankschedules", payload).then(response => {
      if (response) {
        context.commit("BANKSCHEDULE_SAVED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("bankschedules/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("BANKSCHEDULE_UPDATED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("BANKSCHEDULE_FETCHED", response);
      }
    });
  },
  async delete(context, id) {
    await _resource.destroy("bankschedules/" + id).then(response => {
      if (response) {
        context.commit("BANKSCHEDULE_DELETED", response);
      }
    });
  }
};

const mutations = {
  BANKSCHEDULE_SAVED: (state, data) => {
    state.status = data.success;
    state.data.data.unshift(data.data)
  },
  BANKSCHEDULE_UPDATED: (state, data) => {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id);
    state.data.data.splice(index, 1, res)

    state.status = data.success;
  },
  BANKSCHEDULE_FETCHED(state, payload) {
    state.data = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  BANKSCHEDULE_DELETED: (state, data) => {
    let res = data.data;
    var index = state.data.findIndex(item => item.id === res.id)
    state.data.splice(index, 1)
    state.status = data.success;
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const bankschedule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
