import axios from 'axios'

axios.defaults.baseURL = `${process.env.VUE_APP_ROOT_API}`

var conf = {
  baseURL: `${process.env.VUE_APP_ROOT_API1}`
}

export const _resource = {
  query,
  save,
  update,
  destroy,
  setHeaders
};

async function query(address) {
  this.setHeaders()

  return await axios.get(`/${address}`, conf).then(response => response.data);
}

async function save(address, payload) {
  this.setHeaders()
  return await axios
    .post(address, payload, conf)
    .then(response => response.data);
}

async function update(address, payload) {
  this.setHeaders()
  return await axios
    .put(address, payload, conf)
    .then(response => response.data);
}

async function destroy(address) {
  this.setHeaders()
  return await axios.delete(address, conf).then(response => response.data);
}

function setHeaders () {
  let accessToken = localStorage.token
  axios.defaults.headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Authorization' : `Bearer ${accessToken}`
  }
}
