<template src="./Withdrawal.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import FileUpload from "@/partials/FileUpload/FileUpload.vue";
import { mapState, mapActions } from "vuex";

import { _resource } from "@/api/_resource";

const container = {
  state: mapState({
    status: state => state.withdrawals.status,
    withdrawals: state => state.withdrawals.data,
    withdrawals_extract: state => state.requests.extract_data,
    merchants: state => state.merchants.lookup
  }),
  actions: mapActions({
    query: "withdrawals/query",
    update: "withdrawals/update",
    complete_withdrawals : "withdrawals/complete",
    extract_withdrawals: "requests/extract",
    save_all_withdrawals: "requests/save_all",
    qry_merchants: "merchants/lookup"
  })
}

export default {
  name: "Withdrawal",
  components: {
    MiniLoader,
    FileUpload
  },
  data() {
    return {
      has_permission_withdrawal_unlockstatus: false,
      export_loader: false,
      import_loader: false,
      show_filter: false,
      complete_loader: false,
      status_loader: false,
      export_query: '',
      withdrawal: {},
      createdAt: '',
      old_status: '',
      clicked: JSON.parse(localStorage.getItem("withdrawals.clicked")),
      updateStatus: {
        id: '',
        status: '',
        resend_callback: false
      },
      filter: {
        search : '',
        merchant_id: '',
        status: '',
        date_from: '',
        date_to: '',
        date_completed_from: '',
        date_completed_to: ''
      },
      qry: '',
      form : {
        selected_ids: []
      },
      export_filename: 'Lion-Gateway[Withdrawals].csv',
      min_date: '',
      max_date: '',
      csv: {
        file: ''
      },
      extract_page: {
        per_page: 10,
        total: 0,
        current: 1,
        count: 0
      },
      show_extracted: [],
      error_data: [],
      role_name: localStorage.getItem("role")
    };
  },
  computed: {
    ...container.state,
    selectAll: {
      set: function (value) {
        var selected = [];

        if (value) {
            this.withdrawals.data.forEach(function (withdrawal) {
              if (withdrawal.status !== 'completed') {
                selected.push(withdrawal.id);
              }
            });
        }

        this.form.selected_ids = selected;
      },
      get: function() {
        return []
      }
    }
  },
  mounted() {
    this.loader = false
    document.body.classList.add('home')

    this.has_permission_withdrawal_unlockstatus = this.hasPermission('withdrawal_unlockstatus')

    // Reactivate the saved filter after a page change
    this.filter.merchant_id = localStorage.getItem("withdrawals.merchant_id")
    if (this.filter.merchant_id === null) {
      this.filter.merchant_id = ''
    }

    this.filter.status = localStorage.getItem("withdrawals.status")
    if (this.filter.status === null) {
      this.filter.status = ''
    }

    this.limitWithdrawalsClicked()

    this.qry = this.buildFilter(this.filter)

    this.getWithdrawals() 
    this.getMerchants()
  },
  methods: {
    ...container.actions,
    getWithdrawals() {
      const self = this
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page + self.qry;

      self.export_query = '/withdrawals/export?' + self.qry;

      self.query("withdrawals" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.withdrawals.total;
        this.page.count = self.withdrawals.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    getMerchants() {
      const self = this
      this.loader = true

      self.qry_merchants ("merchants").then(() => {
        this.loader = false
      })
    },
    setFilter() {
      const self = this

      self.qry = self.buildFilter(self.filter)

      localStorage.setItem('withdrawals.merchant_id', self.filter.merchant_id)
      localStorage.setItem('withdrawals.status', self.filter.status)

      this.page.current = 1

      self.getWithdrawals()
    },
    clearFilter() {
      const self = this

      self.qry = ''

      // for (let key in self.filter) {
      //   self.filter[key] = ''
      // }
      Object.keys(self.filter).forEach(key => {
        self.filter[key] = ''
      });

      self.getWithdrawals()
    },
    exportData() {
      const self = this
      self.export_loader = true
      let accessToken = localStorage.getItem("token")
      var link = process.env.VUE_APP_ROOT_API + self.export_query
      var headers = {
        'responseType' : 'arraybuffer',
        'headers' : {
          'Authorization': `Bearer ${accessToken}`
        }
      }

      self.$http.get(link, headers).then(response => {
        self.export_loader = false
        self.$bvModal.hide('export-summary')
        
        var blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
        var downloadUrl = URL.createObjectURL(blob)
        var a = document.createElement("a")
        a.href = downloadUrl

        a.download =  self.export_filename 
        
        document.body.appendChild(a)
        a.click()
      })
    },
    setMinDate() {
      const self = this
      self.min_date = self.$moment(self.filter.date_from).format('YYYY-MM-DD')
    },
    setMaxDate() {
      const self = this
      self.max_date = self.$moment(self.filter.date_to).format('YYYY-MM-DD')
    },
    // sendComplete() {
    //   const self = this
    //   self.complete_loader = true
    //
    //   self.complete_withdrawals(self.form).then(() => {
    //     self.$bvModal.hide('modal-complete')
    //     self.complete_loader = false
    //     self.$toast.default('Update to complete done.')
    //   }).catch(() => {
    //     self.complete_loader = false
    //   })
    // },
    limitWithdrawalsClicked() {
      const self = this
      
      // Highest requests per day = 1577 last August 26, 1537 last September 14. Last checked was 2022-09-22
      if (!(self.clicked === null) && self.clicked.length > 1577) {
        self.clicked.splice(0, self.clicked.length - 1577)

        localStorage.setItem('withdrawals.clicked', JSON.stringify(self.clicked))
      }
    },
    setBackgroundColorClass(id) {
      let self = this
      let bgcolorclass = '';

      if (!(self.clicked === null)) {
        const index = self.clicked.findIndex(data => data === id)

        if (!(index === -1)) {
          bgcolorclass = 'button_clicked'
        }
      }

      return bgcolorclass;
    },
    async setSelected(item) {
      const self = this

      // Save for coloring
      if (self.clicked === null) {
        self.clicked = []
        self.clicked.push(item.id)
      } else {
        const index = self.clicked.findIndex(data => data === item.id)
        if (index === -1) {
          self.clicked.push(item.id)
        }
      }
      
      localStorage.setItem('withdrawals.clicked', JSON.stringify(self.clicked))

      //
      self.updateStatus.resend_callback = false

      // Get the latest status
      await _resource
          .query('withdrawal/' + item.id)
          .then(response => {
            self.withdrawal = response.data

            self.createdAt = this.$moment(self.withdrawal.created_at).format("YYYY/MM/DD hh:mm A")

            self.old_status = self.withdrawal.status

            // Update form
            self.updateStatus.id = self.withdrawal.id
            self.updateStatus.status = self.withdrawal.status

            // replace the item at index to update the list
            // let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
            // self.withdrawals.data.splice(index, 1, self.withdrawal)
          })

      if (self.withdrawal.status === 'processing') {
        if (self.has_permission_withdrawal_unlockstatus) {
          this.$bvModal.show('modal-view-details');
        } else {
          let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
          self.withdrawals.data.splice(index, 1, self.withdrawal)

          self.$toast.info('This withdrawal is in process.', {
            duration: 5000
          })
        }
      } else {
        let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
        self.withdrawals.data.splice(index, 1, self.withdrawal)

        if (self.withdrawal.status === 'approved' || self.withdrawal.status === 'verifying') {
          // Update the status to processing
          let payload = {
            status: 'processing'
          }

          await _resource
              .update('withdrawal/' + item.id, payload)
              .then(response => {
                self.withdrawal = response.data

                self.updateStatus.status = self.withdrawal.status

                // replace the item at index to update the list
                // let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
                // self.withdrawals.data.splice(index, 1, self.withdrawal)
              })

          // Show the form with bank details
          this.$bvModal.show('modal-view-details');
        } else {
          if (self.withdrawal.status === 'applying' || self.withdrawal.status === 'completed') {
            this.$bvModal.show('modal-view-details');
          } else {
            // declined, cancelled
            if (self.has_permission_withdrawal_unlockstatus) {
              this.$bvModal.show('modal-view-details');
            } else {
              //self.$toast.default('This withdrawal was ' + self.withdrawal.status + '.')
            }
          }
        }
      }
    },
    setUpdateStatus(item) {
      let self = this

      self.withdrawal = item

      self.createdAt = this.$moment(self.withdrawal.created_at).format("YYYY/MM/DD hh:mm A")

      self.$bvModal.show('modal-status')
    },
    async sendUpdateStatus(id, status, resend_callback = false) {
      const self = this

      if (status === 'processing') {
        status = self.old_status
      }

      let payload = {
        // id: id,
        status: status,
        resend_callback: resend_callback ? 1 : 0
      }

      self.status_loader = true

      await _resource
          .update('withdrawal/' + id, payload)
          .then(response => {
            self.withdrawal = response.data

            let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
            self.withdrawals.data.splice(index, 1, self.withdrawal)

            //self.$bvModal.hide('modal-status')
            self.$bvModal.hide('modal-view-details')
            self.status_loader = false
            //self.$toast.default('Status update done.')
          })
          .catch(() => {
            self.status_loader = false
          })
    },
    sendExtract() {
      var formUpload = this.$refs.file_upload
      if (formUpload.validate()) {
        this.submitExtract(formUpload)
      }
      return false
    },
    submitExtract(formUpload) {
      let self = this
      let formData = new FormData()
      formData.append("import_file", formUpload.$refs.file.files[0])

      self.import_loader = true      
      
      self.extract_withdrawals(formData).then(() => {
        self.extract_page.count = Math.ceil(
          self.withdrawals_extract.data.length / self.extract_page.per_page
        );
        self.prepareList(0, self.extract_page.per_page)
        self.$bvModal.hide('show-import')
        self.$bvModal.show('show-extract')
        self.import_loader = false    
      }).catch((e) => {
        self.error_data = e.response.data.data
        self.import_loader = false
      })
    },
    prepareList(start, end) {
      const self = this
      self.show_extracted = self.withdrawals_extract.data.slice(start, end)
     
    },
    saveAllUpdate() {
      const self = this
      var payload = {
        data : JSON.stringify(self.withdrawals_extract.data)
      }

      self.extract_loader = true

      self.save_all_withdrawals(payload).then(() => {
        self.$bvModal.hide('show-extract')
        self.extract_loader = false
        self.clearFilter()
      }).catch(() => {
        self.extract_loader = false
      })
    }
  }
};
</script>
<style scoped src="./Withdrawal.css"></style>
