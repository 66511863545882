<template src="./BankSchedules.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue"

import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const container = {
  state: mapState({    
    status: state => state.bankschedule.status,
    bankschedules: state => state.bankschedule.data,
    // bankgroups: state => state.bankgroups.data
    banks: state => state.banks.data,
  }),
  actions: mapActions({
    query: "bankschedule/query",
    save: "bankschedule/store",
    update: "bankschedule/update",
    delete: "bankschedule/delete",
    // query_group: "bankgroups/query",
    query_banks: "banks/query",
  })
}

export default {
  name: "BankSchedules",
  components: {
    MiniLoader,
    DatePicker
  },
  data() {
    return {
      add_loader: false,
      edit_loader: false,
      delete_loader: false,
      form: {
        bank_number: '',
        switch_datetime: ''
      },
      edit: {
        id: '',
        bank_number: '',
        switch_datetime: ''
      },
      delete_payload: {
        id: ''
      }
    }
  },
  validations: {
    form: {
      bank_number: {required},
      switch_datetime: {required}
    },
    edit: {
      id: {},
      bank_number: {required},
      switch_datetime: {required}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted () {
    this.loader = false
    this.getBankSchedules()
    // this.getBankGroups()
    this.getBanks()
  },
  methods: {
    ...container.actions,
    // getBankGroups() {
    //   const self = this
    //   this.loader = true
    //
    //   self.query_group("bank-groups").then(() => {
    //     this.loader = false
    //   }).catch(() => {
    //     this.loader = false
    //   })
    // },
    getBankSchedules() {
      const self = this;
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page;

      self.query("bankschedules" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.bankschedules.total;
        this.page.count = self.bankschedules.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    getBanks() {
      const self = this
      this.loader = true

      self.query_banks("banks").then(() => {
        //self.setBankList()
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    showAddForm() {
      const self = this

      //const currentDate = self.$moment().format('YYYY-MM-DD')
      let currentDate = self.$moment()

      let lastSchedule = {
        bank_number: 1,
        switch_datetime: currentDate.format('YYYY-MM-DD') + '06:00:00'
      }

      if (self.bankschedules.data.length > 0) {
        lastSchedule = self.bankschedules.data[0]

        currentDate = self.$moment(lastSchedule.switch_datetime.split(' ')[0], 'YYYY-MM-DD HH:mm:ss')

        if (lastSchedule.switch_datetime.split(' ')[1] === '21:00:00') {
          currentDate = currentDate.add(1, 'd');
        }
      }

      self.form.bank_number = lastSchedule.bank_number === 1 ? 2 : 1;

      self.form.switch_datetime = lastSchedule.switch_datetime.split(' ')[1] === '06:00:00' ? currentDate.format('YYYY-MM-DD') + ' ' + '21:00:00' : currentDate.format('YYYY-MM-DD') + ' ' + '06:00:00'

      self.$bvModal.show('show-add')
    },
    submitCreate() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.saveBank()
      }

      return false
    },
    submitUpdate(){
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateBankSchedule()
      }

      return false
    },
    saveBank() {
      const self = this;
      self.add_loader = true;
      self
        .save(self.form)
        .then(() => {
            self.add_loader = false;
            self.$toast.default('Bank schedule added successfully.')
            self.$bvModal.hide('show-add');
            self.clearForms()
            self.getBankSchedules()
        }).catch(() => {
          self.add_loader = false
        })
    },
    updateBankSchedule() {
      const self = this;
      self.edit_loader = true;
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;
            self.$toast.default('Bank schedule updated successfully.')
            self.$bvModal.hide('show-edit');
            self.clearForms()
            self.getBankSchedules()
        }).catch(() => {
          self.add_loader = false
        })
    },
    showEdit(item) {
      const self = this;
      self.edit.id = item.id
      self.edit.bank_number = item.bank_number
      self.edit.switch_datetime = item.switch_datetime

      self.$bvModal.show('show-edit')
    },
    showDelete(item) {
      this.delete_payload.id = item.id
      this.$bvModal.show('delete_schedule')
    },
    deleteSchedule() {
      const self = this;
      self.delete_loader = true
      self
          .delete(self.delete_payload.id)
          .then(() => {
            self.delete_loader = false;
            self.$toast.default('Schedule deleted successfully.')
            self.$bvModal.hide('delete_schedule');
            self.delete_payload.id = ''
          })
          .catch((error) => {
            if (!error.response.data.success) {
              self.$toast.default(error.response.data.message);
            }
            self.delete_loader = false
            self.$bvModal.hide('delete_schedule');
          })
    },
    clearForms() {
      const self = this;
      for (const prop in self.form) {
        self.form[prop] = "";
      }

      /*for (const prop in self.edit) {
        self.edit[prop] = "";
      }*/
      self.$v.form.$reset();
      //self.$v.edit.$reset();
    },
  }
};
</script>
<style scoped src="./BankSchedules.css"></style>
