import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("role", payload).then(response => {
      if (response) {
        context.commit("ROLE_SAVED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("ROLE_FETCHED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("role/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("ROLE_UPDATED", response);
      }
    });
  },
  async delete(context, id) {
    await _resource.destroy("role/" + id).then(response => {
      if (response) {
        context.commit("ROLE_DELETED", response);
      }
    });
  }
};

const mutations = {
  ROLE_FETCHED(state, payload) {
    state.data = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  ROLE_SAVED: (state, data) => {
    let res = data;
    state.status = res.success;
    state.data.push(res.data)
  },
  ROLE_DELETED: (state, data) => {
    let res = data.data;
    //state.data.push(res);
    var index = state.data.findIndex(item => item.id === res.id)
    state.data.splice(index, 1)
    state.status = data.success;
  },
  ROLE_UPDATED: (state, data) => {
    let res = data.data;
    var index = state.data.findIndex(item => item.id === res.id)
    state.data.splice(index, 1, res)
    state.status = data.success;
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const roles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
