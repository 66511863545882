<template src="./BankGroup.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue"

import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({    
    status: state => state.bankgroups.status,
    bankgroups: state => state.bankgroups.data,
    bankaccounts: state => state.bankaccounts.data,
    banks: state => state.banks.data,
  }),
  actions: mapActions({
    query: "bankgroups/query",
    save: "bankgroups/store",
    update: "bankgroups/update",
    query_bankaccounts: "bankaccounts/query",
    query_banks: "banks/query",
    mng_bank: "bankgroups/add_banks"
  })
}

export default {
  name: "BankGroup",
  components: {
    MiniLoader
  },
  data() {
    return {
      add_loader: false,
      edit_loader: false,
      add_bank_loader: false,
      form: {
        bank_number: 1,
        group_name: ''
      },
      edit: {
        id: '',
        bank_number: '',
        group_name: ''
      },
      add_bank: '',
      selected_banks: [],
      selected_group: ''
    }
  },
  validations: {
    form: {
      bank_number: {},
      group_name: {required}
    },
    edit: {
      bank_number: {},
      group_name: {required}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted () {
    this.loader = false

    this.getBanks()
    this.getBankAccounts()
    this.getBankGroups()
  },
  methods: {
    ...container.actions,

    getBanks() {
      const self = this
      this.loader = true

      self.query_banks("banks").then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    getBankAccounts() {
      const self = this
      this.loader = true

      self.query_bankaccounts("banks").then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    getBankGroups() {
      const self = this;
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page

      self.query("bank-groups" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.bankgroups.total;
        this.page.count = self.bankgroups.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    submitCreate() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.saveBankGroup()
      }

      return false
    },
    submitUpdate(){
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateBankGroup()
      }

      return false
    },
    saveBankGroup() {
      const self = this;
      self.add_loader = true;
      self
        .save(self.form)
        .then(() => {
            self.add_loader = false;
            self.$toast.default('Bank added successfully.')
            self.$bvModal.hide('show-add');
            self.clearForms()
            self.getBankGroups()
        }).catch(() => {
          self.add_loader = false
        })
    },
    updateBankGroup() {
      const self = this;
      self.edit_loader = true;
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;
            self.$toast.default('Bank updated successfully.')
            self.$bvModal.hide('show-edit');
            self.clearForms()
            self.getBankGroups()
        }).catch(() => {
          self.add_loader = false
        })
    },
    showEdit(item) {
      const self = this
      self.edit.id = item.id
      self.edit.bank_number = item.bank_number
      self.edit.group_name = item.group_name
      self.$bvModal.show('show-edit')
    },
    clearForms() {
      const self = this
      for (var prop in self.form) {
        self.form[prop] = "";
      }

      /*for (var prop in self.edit) {
        self.edit[prop] = "";
      }*/
      self.$v.form.$reset();
      //self.$v.edit.$reset();
    },
    addBank() {
      const self = this
      const index = self.bankaccounts.findIndex(item => item.id == self.add_bank)

      var add = {
        bank_name: self.bankaccounts[index].bank_name,
        id: self.bankaccounts[index].id
      }

      var isExist = -1

      isExist = self.selected_banks.findIndex(item => item.id == self.add_bank)

      if (isExist < 0) {        
        self.selected_banks.push(add)
        self.add_bank = ''
      }
    },
    removeBank(id) {
      const self = this
      const index = self.selected_banks.findIndex(item => item.id === id)
      self.selected_banks.splice(index, 1)
    },
    showList(group) {
      const self = this
      self.selected_group = group;
      self.selected_banks = group.banks.slice();
      self.$bvModal.show('show-list');
    },
    submitGrouping() {
      const self = this
      var permitted = self.selected_banks.map(value => value.id)

      var payload = {
        id: self.selected_group.id,
        banks: permitted
      }

      self.add_bank_loader = true;
      self.mng_bank(payload).then(() => {
        self.$toast.default('Bank list successfully updated.')
        self.add_bank_loader = false;
      }).catch(() => {
        self.add_bank_loader = false;
      })
    }
  }
};
</script>
<style scoped src="./BankGroup.css"></style>
