<template src="./Merchant.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";

import { required, minLength, email } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.merchants.status,
    merchants: state => state.merchants.data,
    users: state => state.merchants.users,
    _user_roles: state => state.merchantusers.roles,
    roles: state => state.roles.data,
    bankgroups: state => state.bankgroups.lookup,
    merchant_bankgroups: state => state.merchants.bankgroups,
    banks: state => state.merchants.banks,
    merchant_remittance_bankaccounts: state => state.remittancebankaccounts.lookup
  }),
  actions: mapActions({
    query: "merchants/query",
    create: "merchants/store",
    update: "merchants/update",
    delete: "merchants/delete",
    //merchantUsers: "merchantusers/query",
    updateUser: "merchantusers/update",
    create_token: "merchants/create_token",
    delete_token: "merchants/delete_token",
    query_group: "bankgroups/lookup",
    _addBankGroup: "merchants/add_bankgroup",
    _getUsers: "merchants/get_users",
    qry_roles: "roles/query",
    _getBankgroups: "merchants/get_bankgroups",
    _getBanks: "merchants/get_banks",
    _deleteMerchantBankgroup: "merchants/delete_bankgroup",
    _getRemittanceBankaccounts: "remittancebankaccounts/lookup"
  })
}

export default {
  name: "Merchant",
  components: {
    MiniLoader
  },
  data() {
    return {
      submit_loader: false,
      edit_loader: false,
      delete_loader: false,
      user: {},
      user_roles: {},
      filter: {
        search: ''
      },
      qry: '',
      togglePassword: 'bx-show-alt',
      togglePasswordType: 'password',
      form: {
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        password: '',
        callback_url: 'https://api.lion-gateway.com/callback',
        active: '',
        status: false,
        program_name: '',
        remittance_bankaccount_id: '',
        is_vip: '',
        send_deposit_callback: true,
        send_confirmation_email: true,
        has_on_hold: '',
        send_onhold_email: false,
        send_withdrawal_callback: true,
        resend_deposit_callback: true,
        default_withdrawal_status: 'approved',
        send_withdrawal_callback_by_status: '',
        send_withdrawal_callback_for_refund: true,
        send_withdrawal_callback_for_declined: true,
        send_withdrawal_callback_for_cancelled: true,
        scrape_matching: '',
        is_test: '',
        enable_2fa: true,
        ip: '',
        enable_ipwhitelist: false,
        has_deposits_view: false,
        has_reports_view: false,
        role: '',
      },
      bank_group: {
        merchant_id: '',
        merchant_company_name: '',
        bank_group_id: ''
      },
      edit: {
        id: '',
        company: '',
        email: '',
        first_name: '',
        last_name: '',
        callback_url: '',
        active: '',
        status: false,
        type: '',
        is_vip: '',
        program_name: '',
        bank_group_id: '',
        remittance_bankaccount_id: '',
        send_deposit_callback: true,
        resend_deposit_callback: true,
        send_confirmation_email: true,
        has_on_hold: '',
        send_onhold_email: false,
        default_withdrawal_status: 'approved',
        send_withdrawal_callback: true,
        send_withdrawal_callback_by_status: '',
        send_withdrawal_callback_for_refund: true,
        send_withdrawal_callback_for_declined: true,
        send_withdrawal_callback_for_cancelled: true,
        is_money_matrix: '',
        scrape_matching: '',
        is_test: '',
        enable_2fa: true,
        ip: '',
        enable_ipwhitelist: false,
        has_deposits_view: false,
        has_reports_view: false,
        role: '',
      },
      delete_payload: {
        id: ''
      },
      token: {
        merchant_id: ''      
      },
      revoke: {
        id: '',
        token: ''
      },
      activeChecked :false
    };
  },
  validations: {
    form: {
      company: {required},
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      first_name:{},
      last_name:{},
      active: {},
      status: {},
      callback_url: {},
      program_name: {},
      remittance_bankaccount_id: {},
      is_vip: {},
      send_deposit_callback: {},
      resend_deposit_callback: {},
      send_confirmation_email: {},
      has_on_hold: {},
      send_onhold_email: {},
      default_withdrawal_status: {},
      send_withdrawal_callback: {},
      send_withdrawal_callback_for_refund: {},
      send_withdrawal_callback_for_declined: {},
      send_withdrawal_callback_for_cancelled: {},
      scrape_matching: {},
      is_test: {},
      enable_2fa: {},
      ip: {},
      enable_ipwhitelist: {},
      has_deposits_view: {},
      has_reports_view: {},
      role: {}
    },
    edit: {
      id: '',
      company: {required},
      email: {
        required,
        email
      },
      first_name:{},
      last_name:{},
      active: {},
      status: {},
      callback_url: {},
      type: {},
      is_vip: {},
      program_name: {},
      bank_group_id: {required},
      remittance_bankaccount_id: {},
      send_deposit_callback: {},
      resend_deposit_callback: {},
      send_confirmation_email: {},
      has_on_hold: {},
      send_onhold_email: {},
      send_withdrawal_callback: {},
      default_withdrawal_status: {},
      send_withdrawal_callback_for_refund: {},
      send_withdrawal_callback_for_declined: {},
      send_withdrawal_callback_for_cancelled: {},
      is_money_matrix: {},
      scrape_matching: {},
      is_test: {},
      enable_2fa: {},
      ip: {},
      enable_ipwhitelist: {},
      has_deposits_view: {},
      has_reports_view: {},
      role: {}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
    document.body.classList.add('home')
    this.getMerchants()
    this.getRoles()
    this.getBankGroups()
    this.getRemittanceBankaccounts()
  },
  methods: {
    ...container.actions,
    getMerchants() {
      const self = this
      this.loader = true
      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page + self.qry;

      self.query("merchants" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.merchants.total;
        this.page.count = self.merchants.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    getRoles() {
      const self = this
      this.loader = true
      self.qry_roles("roles/merchant").then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    getBankGroups() {
      const self = this
      this.loader = true
      self.query_group("bank-groups").then(() => {
        this.loader = false
      })
    },
    getRemittanceBankaccounts() {
      const self = this
      this.loader = true
      self._getRemittanceBankaccounts("remittancebankaccounts").then(() => {
          this.loader = false
      })
    },
    getUsers(merchant_id) {
      const self = this
      this.loader = true

      self._getUsers("merchants" + "?filter[type]=3&filter[merchant_id]=" + merchant_id).then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    getMerchantBankgroups(merchant_id) {
      const self = this
      this.loader = true

      self._getBankgroups("merchants/" + merchant_id + "/bankgroups").then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    getBanks(merchant_id) {
      const self = this
      this.loader = true

      self._getBanks("merchants/" + merchant_id + "/bankgroups").then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    linkBankGroup() {
      const self = this
      this.loader = true
      const data = {
        id: self.bank_group.merchant_id,
        bank_group_id: self.bank_group.bank_group_id
      }
      self._addBankGroup(data).then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    removeBankGroup(bankgroup) {
      const self = this
      this.loader = true

      self._deleteMerchantBankgroup("merchants/" + bankgroup.merchant_id + "/bankgroups/" + bankgroup.bank_group_id).then(() => {
        const index = self.merchant_bankgroups.findIndex(item => item.bank_group_id === bankgroup.bank_group_id)
        self.merchant_bankgroups.splice(index, 1)

        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    setFilter() {
      const self = this

      self.qry = self.buildFilter(self.filter)

      this.page.current = 1

      self.getMerchants()
    },
    clearFilter() {
      const self = this

      self.qry = ''

      for (let key in self.filter) {
        self.filter[key] = ''
      }

      this.page.current = 1

      self.getMerchants()
    },
    submitCreate() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.saveMerchant()
      }

      return false
    },
    saveMerchant() {
      const self = this;
      self.submit_loader = true;
      self
        .create(self.form)
        .then(() => {
            self.submit_loader = false;
            self.$toast.default('Merchant saved successfully.')
            self.$bvModal.hide('show-add');
            self.clearForms()
        }).catch(() => {
          self.submit_loader = false
        })
    },
    submitUpdate(is_user = false) {
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateMerchant(is_user)
      }

      return false
    },
    submitUpdateUser() {
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateMerchantUser()
      }

      return false
    },
    updateMerchant(is_user = false) {
      const self = this;
      self.edit_loader = true;
      self.edit.status = self.edit.active
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;

            self.$toast.default('Merchant updated successfully.')
          if (is_user) {
            const index = self.users.data.findIndex(item => item.id === self.edit.id)
            self.users.data.splice(index, 1, self.edit)
            self.$bvModal.hide('show-edit-user');
          } else {
            self.$bvModal.hide('show-edit');
          }

          self.clearForms()
        }).catch(() => {
          self.edit_loader = false
        })
    },
    updateMerchantUser() {
      const self = this;
      self.edit_loader = true;

      self
          .updateUser(self.edit)
          .then(() => {
            self.edit_loader = false;

            self.$toast.default('Merchant updated successfully.')

            const index = self.users.findIndex(item => item.id === self.edit.id)

            self.edit.user = self.user
            self.edit.user.roles = self._user_roles

            self.users.splice(index, 1, self.edit)

            self.$bvModal.hide('show-edit-user');

            // self.clearForms()
          }).catch(() => {
        self.edit_loader = false
      })
    },
    clearForms() {
      const self = this
      for (let prop in self.form) {
        self.form[prop] = "";
      }

      for (let prop in self.edit) {
        self.edit[prop] = "";
      }
      self.$v.form.$reset();
      self.$v.edit.$reset();
    },
    togglePasswordSwitch() {
      if (this.togglePassword == 'bx-show-alt') {
        this.togglePassword = 'bx-low-vision';
        this.togglePasswordType = 'text';
      } else {
        this.togglePassword = 'bx-show-alt';
        this.togglePasswordType = 'password';
      }
    },
    createToken(id) {
      const self = this;
      this.loader = true;
      self.token.merchant_id = id;
      self
        .create_token(self.token)
        .then(() => {
          this.loader = false;
          self.$toast.default('Token added successfully.')
          self.$bvModal.hide('show-add-token');
          self.clearForms()
        }).catch(() => {
          this.loader = false
        })
    },
    onCopy () {
      this.$toast.default('Token copied!')
    },
    showDeleteToken(id, token) {
      this.revoke.id = id;
      this.revoke.token = token;
      this.$bvModal.show('show-revoke')
    },
    deleteToken() {
      const self = this
      self.submit_loader = true;
      self.delete_token(self.revoke)
        .then(() => {
            self.submit_loader = false;
            self.$toast.default('Token deleted successfully.')
            self.$bvModal.hide('show-revoke');
        }).catch(() => {
          self.submit_loader = false
        })
    },
    showEditMerchant(merchant) {
      const self = this

      self.edit.id = merchant.id;

      self.edit.company = merchant.company;
      self.edit.email = merchant.email;

      self.edit.first_name = merchant.first_name;
      self.edit.last_name = merchant.last_name;

      self.edit.callback_url = merchant.callback_url;

      self.edit.program_name = merchant.program_name;
      self.edit.bank_group_id = merchant.bank_group_id ? merchant.bank_group_id : '';
      self.edit.remittance_bankaccount_id = merchant.remittance_bankaccount_id ? merchant.remittance_bankaccount_id : '';

      self.edit.type = merchant.type
      self.edit.is_vip = merchant.is_vip;

      self.edit.send_deposit_callback = merchant.send_deposit_callback
      self.edit.resend_deposit_callback = merchant.resend_deposit_callback

      self.edit.send_confirmation_email = merchant.send_confirmation_email
      self.edit.has_on_hold = merchant.has_on_hold
      self.edit.send_onhold_email = merchant.send_onhold_email
      self.edit.default_withdrawal_status = merchant.default_withdrawal_status
      self.edit.send_withdrawal_callback = merchant.send_withdrawal_callback

      self.edit.send_withdrawal_callback_for_refund = merchant.send_withdrawal_callback_for_refund
      self.edit.send_withdrawal_callback_for_declined = merchant.send_withdrawal_callback_for_declined
      self.edit.send_withdrawal_callback_for_cancelled = merchant.send_withdrawal_callback_for_cancelled

      self.edit.is_money_matrix = merchant.is_money_matrix;
      self.edit.scrape_matching = merchant.scrape_matching;

      self.edit.active = merchant.user.active;

      self.edit.status = merchant.status
      self.edit.is_test = merchant.is_test;
      self.edit.enable_2fa = merchant.enable_2fa;

      self.edit.ip = merchant.ip;
      self.edit.enable_ipwhitelist = merchant.enable_ipwhitelist

      self.edit.has_deposits_view = merchant.has_deposits_view;
      self.edit.has_reports_view = merchant.has_reports_view;

      self.activeChecked = merchant.user.active

      self.$bvModal.show('show-edit')
    },
    showMerchantUsers(merchant) {
      let self = this

      self.getUsers(merchant.id)

      self.$bvModal.show('show-merchant-users')
    },
    showEditMerchantUser(merchant) {
      let self = this

      // Save User object
      self.user = merchant.user
      self.user_roles = merchant.user.roles

      //
      self.edit.id = merchant.id;
      self.edit.company = merchant.company;
      self.edit.email = merchant.email;
      self.edit.first_name = merchant.first_name;
      self.edit.last_name = merchant.last_name;
      self.edit.callback_url = merchant.callback_url;
      self.edit.program_name = merchant.program_name;
      self.edit.bank_group_id = merchant.bank_group_id ? merchant.bank_group_id : '';
      self.edit.remittance_bankaccount_id = merchant.remittance_bankaccount_id ? merchant.remittance_bankaccount_id : '';
      self.edit.type = merchant.type
      self.edit.is_vip = merchant.is_vip;
      self.edit.send_deposit_callback = merchant.send_deposit_callback
      self.edit.resend_deposit_callback = merchant.resend_deposit_callback
      self.edit.send_confirmation_email = merchant.send_confirmation_email
      self.edit.has_on_hold = merchant.has_on_hold
      self.edit.send_onhold_email = merchant.send_onhold_email
      self.edit.default_withdrawal_status = merchant.default_withdrawal_status
      self.edit.send_withdrawal_callback = merchant.send_withdrawal_callback
      self.edit.send_withdrawal_callback_for_refund = merchant.send_withdrawal_callback_for_refund
      self.edit.send_withdrawal_callback_for_declined = merchant.send_withdrawal_callback_for_declined
      self.edit.send_withdrawal_callback_for_cancelled = merchant.send_withdrawal_callback_for_cancelled
      self.edit.is_money_matrix = merchant.is_money_matrix;
      self.edit.scrape_matching = merchant.scrape_matching;
      self.edit.active = merchant.user.active;
      self.edit.is_test = merchant.is_test;
      self.edit.enable_2fa = merchant.enable_2fa;
      self.edit.ip = merchant.ip;
      self.edit.enable_ipwhitelist = merchant.enable_ipwhitelist;
      self.edit.has_deposits_view = merchant.has_deposits_view;
      self.edit.has_reports_view = merchant.has_reports_view;

      self.edit.role = merchant.user.roles[0].name;
      self.edit.status = merchant.status

      self.activeChecked = merchant.user.active;

      self.$bvModal.show('show-edit-user')
    },
    showBankaccounts(merchant) {
      const self = this

      self.getMerchantBankgroups(merchant.id)

      self.bank_group.merchant_id = merchant.id;
      self.bank_group.merchant_company_name = merchant.company;

      self.$bvModal.show('show-bankaccounts')
    },
    showDelete(item) {
      this.delete_payload.id = item.id
      this.$bvModal.show('show-delete')
    },
    deleteAccount() {
      const self = this;
      self.delete_loader = true
      self
          .delete(self.delete_payload.id)
          .then(() => {
            self.delete_loader = false;
            self.$toast.default('Account deleted successfully.')
            self.$bvModal.hide('show-delete');
            self.delete_payload.id = ''
          })
          .catch(() => {
            self.delete_loader = false
            self.$bvModal.hide('show-delete');
          })
    },
  }
};
</script>
<style scoped src="./Merchant.css"></style>
