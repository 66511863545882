<template src="./ActivityList.html"></template>
<script>

export default {
  name: "ActivityList",
  props: {
    data: {
      type: [Object, Array]
    },
    title: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      masked : ['password']
    };
  }
};
</script>
<style scoped="true" src="./ActivityList.css"></style>
