<template src="./BankAccounts.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue"

import { required, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({    
    status: state => state.bankaccounts.status,
    banks: state => state.bankaccounts.data
  }),
  actions: mapActions({
    query: "bankaccounts/query",
    save: "bankaccounts/store",
    update: "bankaccounts/update"
  })
}

export default {
  name: "BankAccounts",
  components: {
    MiniLoader
  },
  data() {
    return {
      add_loader: false,
      edit_loader: false,
      disable_fields: true,
      form: {
        bank_name: '',
        branch_name: '',
        branch_code: '',
        account_type: '',
        account_number: '',
        account_name: '',
        status: 'active',
        has_ref_number: 0,
        is_vip: 0,
        is_overseas: 0,
        currency: 'JPY',
        bank_code: '',
        iban: '',
        swift: '',
        bank_address:  '',
        inter_bank_name: '',
        inter_bank_address: '',
        inter_swift_code: ''
      },
      edit: {
        id: '',
        bank_name: '',
        branch_name: '',
        branch_code: '',
        account_type: '',
        account_number: '',
        account_name: '',
        status: '',
        has_ref_number: 0,
        is_vip: 0,
        is_overseas: 0,
        currency: '',
        bank_code: '',
        iban: '',
        swift: '',
        bank_address: '',
        inter_bank_name: '',
        inter_bank_address: '',
        inter_swift_code: ''
      }
    }
  },
  validations: {
    form: {
      bank_name: {required},
      branch_name: {required},
      branch_code: {required, minLength: minLength(3), maxLength: maxLength(3)},
      account_type:{required},
      account_number:{required},
      account_name:{required},
      status:{required},
      has_ref_number: {},
      is_vip: {},
      is_overseas: {},
      currency: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      bank_code: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      iban: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      swift: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      bank_address: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_bank_name: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_bank_address: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_swift_code: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      }
    },
    edit: {
      id: {},
      bank_name: {required},
      branch_name: {required},
      branch_code: {required},
      account_type:{required},
      account_number:{required},
      account_name:{required},
      status:{required},
      has_ref_number: {},
      is_vip: {},
      is_overseas: {},
      currency: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      bank_code: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      iban: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      swift: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      bank_address: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_bank_name: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_bank_address: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_swift_code: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      }
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted () {
    this.loader = false
    this.getBanks()
  },
  methods: {
    ...container.actions,
    getBanks() {
      const self = this
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page

      self.query("banks" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.banks.total;
        this.page.count = self.banks.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    submitCreate() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.saveBank()
      }

      return false
    },
    submitUpdate(){
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateBank()
      }

      return false
    },
    saveBank() {
      const self = this;
      self.add_loader = true;
      self
        .save(self.form)
        .then(() => {
            self.add_loader = false;
            self.$toast.default('Bank added successfully.')
            self.$bvModal.hide('show-add');
            self.clearForms()
            self.getBanks()
        }).catch(() => {
          self.add_loader = false
        })
    },
    updateBank() {
      const self = this;
      self.edit_loader = true;
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;
            self.$toast.default('Bank updated successfully.')
            self.$bvModal.hide('show-edit');
            self.clearForms()
            self.getBanks()
        }).catch(() => {
          self.add_loader = false
        })
    },
    showEdit(item) {
      const self = this
      self.edit.id = item.id
      self.edit.bank_name = item.bank_name
      self.edit.branch_name = item.branch_name
      self.edit.branch_code = item.branch_code
      self.edit.account_type = item.account_type
      self.edit.account_number = item.account_number
      self.edit.account_name = item.account_name
      self.edit.status = item.status
      self.edit.has_ref_number = item.has_ref_number
      self.edit.is_vip = item.is_vip
      self.edit.is_overseas = item.is_overseas
      self.edit.currency = item.currency
      self.edit.bank_code = item.bank_code
      self.edit.iban = item.iban
      self.edit.swift = item.swift
      self.edit.bank_address = item.bank_address
      self.edit.inter_bank_name = item.inter_bank_name
      self.edit.inter_bank_address = item.inter_bank_address
      self.edit.inter_swift_code = item.inter_swift_code

      self.disable_fields = (item.is_overseas) ? false: true

      self.$bvModal.show('show-edit')
    },
    clearForms() {
      const self = this
      for (var prop in self.form) {
        self.form[prop] = "";
      }

      /*for (var prop in self.edit) {
        self.edit[prop] = "";
      }*/
      self.$v.form.$reset();
      self.form.has_ref_number = 0;
      self.form.is_vip = 0;
      //self.$v.edit.$reset();
    },
  }
};
</script>
<style scoped src="./BankAccounts.css"></style>
