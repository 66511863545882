import { _resource } from "@/api/_resource";

const state = {
    status: false,
    data: [],
    lookup: [],
    error_code: null
};

const actions = {
    async store(context, payload) {
        await _resource.save("remittancebankaccounts", payload).then(response => {
            if (response) {
                context.commit("BANKACCOUNT_SAVED", response);
            }
        });
    },
    async update({ commit }, payload) {
        await _resource.update("remittancebankaccounts/" + payload.id, payload).then(response => {
            if (response != undefined) {
                commit("BANKACCOUNT_UPDATED", response);
            }
        });
    },
    async query(context, query) {
        await _resource.query(query).then(response => {
            if (response) {
                context.commit("BANKACCOUNT_FETCHED", response);
            }
        });
    },
    async lookup(context, query) {
        await _resource.query(query).then(response => {
            if (response) {
                context.commit("BANKACCOUNT_LOOKUP", response);
            }
        });
    }
};

const mutations = {
    BANKACCOUNT_SAVED: (state, data) => {
        state.status = data.success;
        state.data.data.unshift(data.data)
    },
    BANKACCOUNT_UPDATED: (state, data) => {
        let res = data.data;
        const index = state.data.data.findIndex(item => item.id === res.id)
        state.data.data.splice(index, 1, res)

        state.status = data.success;
    },
    BANKACCOUNT_FETCHED(state, payload) {
        state.data = payload.data;
        if (payload.success) {
            state.status = payload.success;
        }
    },
    BANKACCOUNT_LOOKUP(state, payload) {
        state.lookup = payload.data;
        if (payload.success) {
            state.status = payload.success;
        }
    }
};

const getters = {
    list: state => {
        return state.data;
    }
};

export const remittancebankaccounts = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
