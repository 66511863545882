import Vue from "vue";
import Vuex from "vuex";

import { login } from "./Login/login.store"
import { merchants } from "./Merchants/merchant.store"
import { merchantusers } from "./MerchantUsers/merchant-user.store"
import { requests } from "./Requests/request.store"
import { tokens } from "./Tokens/token.store"
import { withdrawals } from "./Withdrawals/withdrawal.store"
import { forgot_passwords } from "./ForgotPassword/forgot-password.store"
import { change_passwords } from "./ChangePassword/change-password.store"
import { permissions } from "./Permissions/permission-store"
import { roles } from "./Roles/role-store"
import { users } from "./Users/user-store"
import { dashboards } from "./Dashboard/dashboard.store"
import { activity_logs } from "./Activity/activity-log.store"
import { banks } from "./Bank/banks.store"
import { bankaccounts } from "./BankAccounts/bankaccounts.store"
import { bankgroups } from "./BankGroup/bank-group.store"
import { bankschedule } from "./BankSchedule/bankschedule.store"
import { remittancebankaccounts } from "./RemittanceBankaccounts/remittancebankaccounts"
import { scheduledscrapper } from "./ScheduledScrapper/scheduled-scrapper.store"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    merchants,
    merchantusers,
    requests,
    tokens,
    withdrawals,
    forgot_passwords,
    change_passwords,
    permissions,
    roles,
    users,
    dashboards,
    activity_logs,
    banks,
    bankaccounts,
    bankgroups,
    bankschedule,
    remittancebankaccounts,
    scheduledscrapper
  }
})
