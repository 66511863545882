import Vue from "vue";
import Router from "vue-router";
import store from "@/store/";

import Login from "@/components/Login/Login";
import Merchant from "@/components/Merchant/Merchant";
import Request from "@/components/Request/Request";
import Token from "@/components/Token/Token";
import Withdrawal from "@/components/Withdrawal/Withdrawal";
import ResetPassword from '@/components/ResetPassword/ResetPassword'
import Account from '@/components/Account/Account'
import Role from '@/components/Role/Role'
import UserAccount from '@/components/UserAccount/UserAccount'
import Dashboard from '@/components/Dashboard/Dashboard'
import Activity from '@/components/Activity/Activity'
import Bank from '@/components/Bank/Bank'
import BankAccounts from '@/components/BankAccounts/BankAccounts'
import BankGroup from '@/components/BankGroup/BankGroup'
import BankSchedules from '@/components/BankSchedules/BankSchedules'
import RemittanceBankaccounts from '@/components/RemittanceBankaccounts/RemittanceBankaccounts'
import ScheduledScrapper from '@/components/ScheduledScrapper/ScheduledScrapper'
import Report from '@/components/Report/Report'

import { i18n } from '@/main'

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/reset-password/:hash",
      name: "ResetPassword",
      component: ResetPassword
    },
    {
      alias: "/",
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {      
      path: "/merchants",
      name: "Merchant",
      component: Merchant,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/payments",
      name: "Request",
      component: Request,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/tokens",
      name: "Token",
      component: Token,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/withdrawals",
      name: "Withdrawal",
      component: Withdrawal,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/my-account",
      name: "Account",
      component: Account,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/roles-permissions",
      name: "Role",
      component: Role,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user-account",
      name: "UserAccount",
      component: UserAccount,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/activity-logs",
      name: "Activity",
      component: Activity,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/banks",
      name: "Bank",
      component: Bank,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/bankaccounts",
      name: "BankAccounts",
      component: BankAccounts,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/bank-groups",
      name: "BankGroup",
      component: BankGroup,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/bank-schedules",
      name: "BankSchedules",
      component: BankSchedules,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/remittancebankaccounts",
      name: "RemittanceBankaccounts",
      component: RemittanceBankaccounts,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/scrappers",
      name: "ScheduledScrapper",
      component: ScheduledScrapper,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reports",
      name: "Report",
      component: Report,
      meta: {
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('language') || 'en'
  let token = localStorage.getItem("token")
  if (token !== undefined) {
    store.state.token = token
  } else {
    store.state.token = null
  }

  if (to.meta.requiresAuth) {
    if (token) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
