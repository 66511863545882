<template src="./Bank.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue"

import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({    
    status: state => state.banks.status,
    banks: state => state.banks.data
  }),
  actions: mapActions({
    query: "banks/query",
    save: "banks/store",
    update: "banks/update"
  })
}

export default {
  name: "Bank",
  components: {
    MiniLoader
  },
  data() {
    return {
      add_loader: false,
      edit_loader: false,
      disable_fields: true,
      form: {
        name: '',
      },
      edit: {
        id: '',
        name: '',
      }
    }
  },
  validations: {
    form: {
      name: {required},
    },
    edit: {
      id: {},
      name: {required},
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted () {
    this.loader = false
    this.getBanks()
  },
  methods: {
    ...container.actions,
    getBanks() {
      const self = this
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page

      self.query("banks" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.banks.total;
        this.page.count = self.banks.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    submitCreate() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.saveBank()
      }

      return false
    },
    submitUpdate(){
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateBank()
      }

      return false
    },
    saveBank() {
      const self = this;
      self.add_loader = true;
      self
        .save(self.form)
        .then(() => {
            self.add_loader = false;
            self.$toast.default('Bank added successfully.')
            self.$bvModal.hide('show-add');
            self.clearForms()
            self.getBanks()
        }).catch(() => {
          self.add_loader = false
        })
    },
    updateBank() {
      const self = this;
      self.edit_loader = true;
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;
            self.$toast.default('Bank updated successfully.')
            self.$bvModal.hide('show-edit');
            self.clearForms()
            self.getBanks()
        }).catch(() => {
          self.add_loader = false
        })
    },
    showEdit(item) {
      const self = this
      self.edit.id = item.id
      self.edit.name = item.name

      self.$bvModal.show('show-edit')
    },
    clearForms() {
      const self = this
      for (var prop in self.form) {
        self.form[prop] = "";
      }

      /*for (var prop in self.edit) {
        self.edit[prop] = "";
      }*/
      self.$v.form.$reset();
      self.form.has_ref_number = 0;
      self.form.is_vip = 0;
      //self.$v.edit.$reset();
    },
  }
};
</script>
<style scoped src="./Bank.css"></style>
