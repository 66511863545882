import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("token", payload).then(response => {
      if (response) {
        context.commit("TOKEN_SAVED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("TOKEN_FETCHED", response);
      }
    });
  },
  async revoke(context, payload) {
    await _resource.update('token/revoke/' + payload.id).then(response => {
      if (response) {
        context.commit("TOKEN_REVOKED", response);
      }
    });
  }
};

const mutations = {
  TOKEN_SAVED: (state, data) => {
    state.status = data.success;
    state.data.unshift(data.data)
  },
  TOKEN_FETCHED(state, payload) {
    state.data = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  TOKEN_REVOKED: (state, data) => {
    let res = data.data;

    var index = state.data.findIndex(item => item.id === res.id)
    state.data.splice(index, 1, res)
    state.status = res.success;
  },
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const tokens = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
