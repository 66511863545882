<template src="./Token.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";

import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.tokens.status,
    tokens: state => state.tokens.data,
    merchants: state => state.merchants.data,
  }),
  actions: mapActions({
    query: "tokens/query",
    create: "tokens/store",
    update_revoke: "tokens/revoke",
    query_merchants: "merchants/query"
  })
}

export default {
  name: "Token",
  components: {
    MiniLoader
  },
  data() {
    return {
      submit_loader: false,
      form: {
        merchant_id: ''      
      },
      merchant_list: [],
      selected_merchant: '',
      revoke: {
        id: '',
        token_id: ''
      }
    };
  },

  validations: {
    form: {
      merchant_id:{
        required
      }
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
    document.body.classList.add('home')
    this.getTokens() 
    this.getMerchants()
  },
  methods: {
    ...container.actions,
    getTokens() {
      const self = this
      this.loader = true
      self.query("tokens").then(() => {
        this.loader = false
      })
    },
    getMerchants() {
      const self = this
      this.loader = true
      self.query_merchants("merchants").then(() => {
        this.loader = false
         self.merchants.forEach(function(currenctValue){
          self.merchant_list.push(currenctValue.email)
         });
      })
    },
    getMerchantId() {
      const self = this

      var index = self.merchants.findIndex(function(currenctValue){
        if (currenctValue.email == self.selected_merchant) {
          return true
        }
      })

      self.form.merchant_id = self.merchants[index].id
    },
    createToken() {
      const self = this;
      self.submit_loader = true;
      self
        .create(self.form)
        .then(() => {
            self.submit_loader = false;
            self.$toast.default('Token added successfully.')
            self.$bvModal.hide('show-add');
            self.clearForms()
        }).catch(() => {
          self.submit_loader = false
        })
    },
    clearForms() {
      const self = this
      for (var prop in self.form) {
        self.form[prop] = "";
      }
      self.$v.$reset();
    },
    onCopy () {
      this.$toast.default('Token copied!')
    },
    showRevoke(id, token_id) {
      this.revoke.id = id;
      this.revoke.token_id = token_id

      this.$bvModal.show('show-revoke')
    },
    revokeToken() {
      const self = this
      self.submit_loader = true;
      self.update_revoke(self.revoke)
        .then(() => {
            self.submit_loader = false;
            self.$toast.default('Token revoked successfully.')
            self.$bvModal.hide('show-revoke');
        }).catch(() => {
          self.submit_loader = false
        })
    }
  }
};
</script>
<style scoped src="./Token.css"></style>
