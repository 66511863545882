<template src="./Sidebar.html"></template>
<script>
import { mapActions } from "vuex";

const container = {
  actions: mapActions({
    logout: "login/logout"
  })
};

export default {
  name: "Sidebar",
  data() {
    return {
      show: false,
      menu_show: false,
      menu:  {
        token: false,
        merchant: false,
        request: false,
        withdrawal: false,
        bank: false,
        bank_accounts: false,
        remittance_bankaccounts: false,
        scheduled_scrapper: false,
        user_account: false,
        role: false,
        activity: false,
        my_account: false,
        reports: false
      },
      active: {
        token: false,
        merchant: false,
        request: false,
        withdrawal: false,
        bank: false,
        bank_accounts: false,
        remittance_bankaccounts: false,
        scheduled_scrapper: false,
        user_account: false,
        role: false,
        activity: false,
        my_account: false,
        reports: false
      }
    }
  },
  watch: {
    $route (to){
      this.setActiveClass(to.path)
      this.menu_show = false
    }
  },
  mounted() {
    this.setActiveClass(this.$router.currentRoute.path)
    this.eventHub.$on('toggleHeaderMenu', data => {
      this.menu_show = data
    })
  },
  methods: {
    ...container.actions,
    setActiveClass(route) {
        this.resetActiveClass()
        var self = this
        if (route.indexOf('/token') >= 0) {
          self.active.token = true
        } else if (route.indexOf('/merchant') >= 0)  {
          self.active.merchant = true
        } else if (route.indexOf('/payment') >= 0) {
          self.active.request = true
        } else if (route.indexOf('/withdrawal') >= 0) {
          self.active.withdrawal = true
        } else if (route.indexOf('/bank') >= 0)  {
          self.active.bank = true
        } else if (route.indexOf('/bankaccounts') >= 0)  {
          self.active.bank_accounts = true
        } else if (route.indexOf('/remittancebankaccounts') >= 0)  {
          self.active.remittance_bankaccounts = true
        } else if (route.indexOf('/scrappers') >= 0)  {
          self.active.scheduled_scrapper = true
        } else if (route.indexOf('/user-account') >= 0) {
          self.active.user_account = true
        } else if (route.indexOf('/roles-permissions') >= 0) {
          self.active.role = true
        } else if (route.indexOf('/activity') >= 0)  {
          self.active.activity = true
        } else if (route.indexOf('/my-account') >= 0) {
          self.active.my_account = true
        } else if (route.indexOf('/reports') >= 0)  {
          self.active.reports = true
        }
    },
    resetActiveClass() {
      for(var x in this.active) {
        this.active[x] = false
      }
    },
    onLogout() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>
<style src="./Sidebar.css"></style>
