import Vue from "vue"
import axios from 'axios'
import App from "./App.vue";
import router from "@/router/"
import store from "@/store/"
import BootstrapVue from "bootstrap-vue"
import Vuelidate from "vuelidate"
import VueToast from 'vue-toast-notification'
import IdleVue from 'idle-vue'
import VueClipboard from 'vue-clipboard2'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Paginate from "vuejs-paginate"

import VueI18n from 'vue-i18n'
import messages from './lang'

import 'vue-material-design-icons/styles.css';
import 'vue-toast-notification/dist/theme-default.css';

require("@assets/css/bootstrap.min.css");
require("@assets/css/override.css");
require("@assets/css/dashboard.css");
require("@assets/css/loader.css");

Vue.config.productionTip = false

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1200000, // 20 minutes
  startAtIdle: false
});

Vue.component("paginate", Paginate);

Vue.use(require('vue-moment'));
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueClipboard)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.use(VueToast, {
  position: 'top-right'
})

Vue.directive("permission", {
  inserted: function(el, binding) {
    let permissions = JSON.parse(localStorage.getItem("permissions"));
    if (permissions.length) {

      if (typeof binding.value == 'object') {
        let permitted = false;
        for (let i=0; i < binding.value.length; i++) {
          if (permissions.indexOf(binding.value[i]) >= 0) {
            permitted = true
            break;
          }
        }

        return permitted;
      } else if (permissions.indexOf(binding.value) >= 0 || binding.value == '') {
        return true;
      }
    }
    el.parentNode.removeChild(el);
  }
})

const eventHub = new Vue()

Vue.mixin({
  data: function () {
     return {
       eventHub: eventHub,
       loader: true,
       page: {
         per_page: 10,
         total: 0,
         count: 0,
         current: 1
       },
     }
  },
  methods: {
    addBadgeClass(status) {
      let badgeClass = 'badge-info';

      if (status == 'completed') {
        badgeClass = 'badge-success';
      } else if (status == 'rejected' || status == 'cancelled' || status == 'canceled') {
        badgeClass = 'badge-danger';
      } else if (status == 'refunded') {
        badgeClass = 'badge-warning';
      }

      return badgeClass;
    },
    addStatusClass(status) {
      let textClass = 'text-muted';

      if (status == 'completed') {
        textClass = 'text-success';
      } else if (status == 'approved') {
        textClass = 'text-info';
      } else if (status == 'declined') {
        textClass = 'text-danger';
      } else if (status == 'on hold') {
        textClass = 'text-warning';
      }

      return textClass;
    },
    formatAmount(value, fractionDigits = 0) {
        let val = (value/1).toFixed(fractionDigits).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    hasPermission(permission) {
      let permissions = JSON.parse(localStorage.getItem("permissions"))
      let has = false
      if (permissions.length) {
        if (permissions.indexOf(permission) >= 0) {
          has =  true
        }
      }
      return has
    },
    buildFilter(filter, no_pretext = false) {
      let params = '';
      for (const [key, value] of Object.entries(filter)) {
        if (value !== '') {
          if (no_pretext) {
            params += '?filter[' + key + ']=' + value
            no_pretext = false
          } else {
            params += '&filter[' + key + ']=' + value
          }
        }
      }

      return params;
    },
  }
})

Vue.prototype.$http = axios

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
