<template src="./Activity.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import ActivityList from "@/partials/ActivityList/ActivityList.vue";

import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.activity_logs.status,
    activities: state => state.activity_logs.data
  }),
  actions: mapActions({
    query: "activity_logs/query"
  })
}


export default {
  name: "Activity",
  components: {
    MiniLoader,
    ActivityList
  },
  data() {
    return {
      show_filter: false,
      filter: {
        log_name: '',
        event: '',
        search : '',
        date_from: '',
        date_to: ''
      },
      qry: '',
      min_date: '',
      max_date: ''
    };
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
    this.getActivities();
  },
  methods:  {
    ...container.actions,
    getActivities() {
      const self = this
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page + self.qry;

      self.query('activity/admin' + qry_string).then(() => {
        this.loader = false;

        this.page.total = self.activities.total;
        this.page.count = self.activities.last_page
      }).catch(() => {
        this.loader = false;
      })
    },
    setMinDate() {
      const self = this
      self.min_date = self.$moment(self.filter.date_from).format('YYYY-MM-DD')
    },
    setMaxDate() {
      const self = this
      self.max_date = self.$moment(self.filter.date_to).format('YYYY-MM-DD')
    },
    setFilter() {
      const self = this

      self.qry = self.buildFilter(self.filter)

      this.page.current = 1

      self.getActivities()
    },
    clearFilter() {
      const self = this

      self.qry = ''

      Object.keys(self.filter).forEach(key => {
          self.filter[key] = ''
      });

      this.page.current = 1

      self.getActivities()
    }
  }
};
</script>
<style src="./Activity.css"></style>
