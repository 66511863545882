import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  selected: null,
  error_code: null,
  id: "",
  export: ''
};

const actions = {
  async store(context, payload) {
    await _resource.save("admin/user", payload).then(response => {
      if (response) {
        context.commit("USER_SAVED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("USER_FETCHED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("admin/user/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("USER_UPDATED", response);
      }
    });
  },
  async delete(context, id) {
    await _resource.destroy("admin/user/" + id).then(response => {
      if (response != undefined) {
        context.commit("USER_DELETED", response);
      }
    });
  },
  async changePassword(context, payload) {
    await _resource.save("users/change-password", payload).then(response => {
      if (response) {
        context.commit("USER_CHANGE_PASSWORD", response);
      }
    });
  }
};

const mutations = {
  USER_SAVED: (state, data) => {
    state.status = data.data.success;
    state.data.unshift(data.data)
  },
  USER_FETCHED(state, payload) {
    state.data = payload.data;
    state.status = payload.success;
  },
  USER_UPDATED(state, data) {
    let res = data.data;
    const index = state.data.findIndex(item => item.id === res.id)
    state.data.splice(index, 1, res)
    state.status = data.success;
  },
  USER_DELETED(state, data) {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id);
    state.data.data.splice(index, 1)
    state.status = data.success;
  },
  USER_CHANGE_PASSWORD(state, payload) {
    state.status = payload.success;
  }
};

const getters = {
  lists: state => {
    return state.data;
  },
  current: state => id => {
    return state.data.filter(data => {
      if (data.id === id) {
        state.selected = data;
        return data;
      }
    });
  }
};

export const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
