<template src="./Report.html"></template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            isLoading: false
        };
    },
    mounted() {
      this.loader = false

        const info = JSON.parse(localStorage.getItem('info'));
        let accessToken = localStorage.getItem('token');

        const headers = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };

        const body = {
            'email': info.email
        };

        if (info) {
            this.isLoading = true;

        axios
            .post(process.env.VUE_APP_ROOT_API + '/sso-broker-admin', body, headers)
            .then((response) => {
                const url = response.data.url;

                window.open(url, '_blank')
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>
<style scoped src="./Report.css"></style>
