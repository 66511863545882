<template src="./UserAccount.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.users.status,
    users: state => state.users.data,
    roles: state => state.roles.data
  }),
  actions: mapActions({
    query: "users/query",
    create: "users/store",
    update: "users/update",
    delete: "users/delete",
    qry_roles: "roles/query"
  })
}


export default {
  name: "UserAccount",
  components: {
    MiniLoader
  },
  data() {
    return {
      add_loader: false,
      edit_loader: false,
      delete_loader: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: '',
        active: ''
      },
      edit: {
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        active: ''
      },
      delete_payload: {
        id: ''
      }
    };
  },
  validations: {
    form: {
      first_name:{required},
      last_name:{required},
      email: {
        required,
        email
      },
      password: {
        required
      },
      password_confirmation: {
        sameAsPassword: sameAs("password")
      },
      role: { required },
      active: {}
    },
    edit: {
      id : {},
      first_name:{required},
      last_name:{required},
      email: {
        required,
        email
      },
      role: { required },
      active: {}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
    this.getRoles()
    this.getUsers()
  },
  methods: {
    ...container.actions,
    getRoles() {
      const self = this

      this.loader = true

      self.qry_roles('roles/admin').then(() => {
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    getUsers(){
      const self = this

      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page

      self.query('admin/users' + qry_string).then(() => {
        this.loader = false
        this.page.total = self.users.total;
        this.page.count = self.users.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    submitAdd() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.sendAdd()
      }

      return false
    },
    sendAdd() {
      const self = this

      self.add_loader = true
      self.create(self.form).then(() => {
        self.add_loader = false
        self.$bvModal.hide('show-add-user')
      }).catch(() => {
        self.add_loader = false
        self.$bvModal.hide('show-add-user')
      })
    },
    showEdit(user) {
      const self = this
      self.edit.id = user.id
      self.edit.first_name = user.first_name;
      self.edit.last_name = user.last_name;
      self.edit.email = user.email;
      self.edit.role = user.roles[0].name;
      self.edit.active = user.active

      self.$bvModal.show('show-edit-user');
    },
    submitEdit() {
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.sendUpdate()
      }

      return false
    },
    sendUpdate() {
      const self = this

      self.edit_loader = true
      self.update(self.edit).then(() => {
        self.edit_loader = false
        self.$bvModal.hide('show-edit-user')
      }).catch(() => {
        self.edit_loader = false
        self.$bvModal.hide('show-edit-user')
      })
    },
    showDelete(item) {
      this.delete_payload.id = item.id
      this.$bvModal.show('show-delete')
    },
    deleteAccount() {
      const self = this;
      self.delete_loader = true
      self
          .delete(self.delete_payload.id)
          .then(() => {
            self.delete_loader = false;
            self.$toast.default('Account deleted successfully.')
            self.$bvModal.hide('show-delete');
            self.delete_payload.id = ''
          })
          .catch(() => {
            self.delete_loader = false
            self.$bvModal.hide('show-delete');
          })
    },
  }
}
</script>
<style src="./UserAccount.css"></style>
