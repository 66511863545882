import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  lookup: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("bank-group", payload).then(response => {
      if (response) {
        context.commit("BANK_GROUP_SAVED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("bank-group/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("BANK_GROUP_UPDATED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("BANK_GROUP_FETCHED", response);
      }
    });
  },
  async lookup(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("BANK_GROUP_LOOKUP", response);
      }
    });
  },
  async add_banks(context, payload) {
    await _resource.save("bank-group/banks", payload).then(response => {
      if (response) {
        context.commit("BANK_GROUP_BANKS_SAVED", response);
      }
    });
  },
};

const mutations = {
  BANK_GROUP_SAVED: (state, data) => {
    state.status = data.success;
    state.data.data.unshift(data.data)
  },
  BANK_GROUP_UPDATED: (state, data) => {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)

    state.status = data.success;
  },
  BANK_GROUP_FETCHED(state, payload) {
    state.data = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  BANK_GROUP_LOOKUP(state, payload) {
    state.lookup = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  BANK_GROUP_BANKS_SAVED: (state, data) => {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)

    state.status = data.success;
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const bankgroups = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
