import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("ACTIVITY_FETCHED", response);
      }
    });
  }
};

const mutations = {
  ACTIVITY_FETCHED(state, payload) {
    state.data = payload.data;
    state.status = payload.success;
  }
};

export const activity_logs = {
  namespaced: true,
  state,
  actions,
  mutations
};
