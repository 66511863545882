import { _resource } from "@/api/_resource";

const state = {
  token: null,
  permissions: [],
  info: [],
  role: ''
};

const actions = {
  async submit(context, payload) {
    await _resource.save("login", payload).then(response => {
      if (response) {
        context.commit("LOGIN_SUBMIT", response);
      }
    });
  },
  async resetPassword(context, payload) {
    await _resource.save("reset-password/" + payload.hash, payload).then(response => {
      if (response) {
        context.commit("MERCHANT_RESET_PASSWORD", response);
      }
    });
  },
  userinfo(context) {
    context.commit("LOGIN_INFO")
  },
  logout(context) {
    context.commit("LOGOUT_SUBMIT");
  }
};

const mutations = {
  LOGIN_SUBMIT: (state, data) => {
    let res = data.data;
    state.token = null;
    state.permissions = [];

    if (data.success) {
      state.token = res.access_token
      state.permissions = res.permissions
      state.info = res.user
      state.role = res.role

      localStorage.setItem("token", res.access_token)
      localStorage.setItem("permissions", JSON.stringify(res.permissions))
      localStorage.setItem("info", JSON.stringify(res.user))
      localStorage.setItem("role", res.role)
    }
  },
  LOGIN_INFO: (state) => {
    state.info = JSON.parse(localStorage.getItem("info"))
    state.permissions = JSON.parse(localStorage.getItem("permissions"))
  },
  LOGOUT_SUBMIT: (state) => {
    state.token = null
    state.permissions = []
    state.info = []

    localStorage.removeItem("token")
    localStorage.removeItem("permissions")
    localStorage.removeItem("info")
    localStorage.removeItem("role")
  },
  LOGIN_RESET: (state) => {
    Object.keys(state).forEach(key => {
      state[key] = null
    })
  },
   LOGIN_RESET_PASSWORD: (state, data) => {
     state.status = data.success;
  }
};

export const login = {
  namespaced: true,
  state,
  actions,
  mutations
};
