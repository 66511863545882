<template>
  <div id="app">
    <div v-if="$router.currentRoute.path != '/login' && $router.currentRoute.path != '/forgot-password' && $router.currentRoute.path.indexOf('/reset-password') < 0"> 
          <Header></Header>
          <div class="container-fluid">
              <div class="row">
                  <Sidebar ref="sidebarMenu"></Sidebar>
                  <main role="main" class="main col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-3 position-relative">
                    <router-view></router-view>
                  </main>
<!--                  <ModalIdle v-if="isIdle" />-->
              </div>
          </div>
    </div>
    <template v-else>
      <router-view></router-view>
      <div class="language-picker">
       <b-dropdown variant="outline-secondary">
        <template #button-content>
            <i class='bx bx-globe'></i>
        </template>
        <b-dropdown-item v-on:click="changeLang('en')">EN</b-dropdown-item>
        <b-dropdown-item v-on:click="changeLang('ja')">JA</b-dropdown-item>
      </b-dropdown>
      </div>
    </template>    
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router/";
import Header from "@/partials/Header/Header.vue";
import Sidebar from "@/partials/Sidebar/Sidebar.vue";
// import ModalIdle from "@/partials/ModalIdle/ModalIdle.vue";

export default {
  name: "default",
  components: {
    Header,
    Sidebar,
    // ModalIdle
  },
  // computed: {
  //   isIdle() {
  //     return this.$store.state.idleVue.isIdle;
  //   },
  // },
  data () {
    return {
      modal_error: {
        content: ''
      },
      error: {
        'validation.unique' : 'Email is already in use.'
      }
    }
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem('language') || 'en'
  },
  created: function () {

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response) {
          let err = error.response

          let selfInstance = this
          let errData = err.data

          selfInstance.modal_error.content = ''

          if (errData.data != undefined ) {// && (typeof(errData.data) == 'object' || typeof(errData.data) == 'array')
            var errorList = Object.values(errData.data)
            for (var i = 0; i < errorList.length; i++) {
              if (Array.isArray(errorList[i])) {
                selfInstance.modal_error.content += errorList[i] + "\n"
              } else {
                selfInstance.modal_error.content += this.error[errorList[i]] + "\n"
              }
            }
          } else if (errData.error != undefined) {
            selfInstance.modal_error.content = errData.error.code + " - " + errData.error.message;
          } else {
            selfInstance.modal_error.content = errData.error_code + " - " + errData.message;
          }

          switch (error.response.status) {
            case 401:
              if (this.$router.currentRoute.path != '/login') {
                router.push("/login");
              }

              break;

            default:
              selfInstance.$bvModal.show("g-error-modal");
              return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    axios.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        if (error.response) {
          switch (error.response.status) {
            case 401:
            case 403:
              router.push("/login");
              break;

            default:
              return false;
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem('language', lang)

      this.$i18n.locale = lang
    }
  }
};
</script>
