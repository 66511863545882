<template src="./Login.html"></template>
<script>
import VueRecaptcha from 'vue-recaptcha'

import { mapState, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import 'boxicons';

const container = {
  state: mapState({
    token: state => state.login.token,
    permissions: state => state.login.permissions
  }),
  actions: mapActions({
    login: "login/submit",
    forgot_password_submit: "forgot_passwords/submit"
  })
};

export default {
  name: "Login",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      site_key: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
      email: "",
      password: "",
      forgot_password_spinner: false,
      forgot_password : {
        email : "",
        recaptcha : ''
      },
      recaptcha_token_error: false
    };
  },
  validations: {
    forgot_password: {
      email: {
        required,
        email
      }, 
      recaptcha : {}
    }
  },
  mounted() {
    this.loader = false
    document.body.classList.remove('home')
  },
  methods: {
    ...container.actions,
    loginPost() {
      let self = this;
      let payload = { email: self.email, password: self.password }

      this.loader = true
      self.login(payload)
        .then(() => {
          this.loader = false
          self.$router.push("/");
        }).catch((error) => {
          var message = error.response.data.message;
          self.$toast.error(message)
          this.loader = false
        })
    },
    confirmForgotPassword() {
      this.$v.forgot_password.$touch();
      if (this.forgot_password.recaptcha == '') {
        this.recaptcha_token_error = true
      }
      if (!this.$v.forgot_password.$invalid && !this.recaptcha_token_error) {
        this.sendForgotPassword()
      }
      return false;
    },
    onCaptchaVerified(recaptchaToken) {
      this.recaptcha_token_error = false
      this.forgot_password.recaptcha = recaptchaToken
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    sendForgotPassword() {
      const self = this
      self.forgot_password_spinner = true
      self.forgot_password_submit(self.forgot_password).then(() => {
        self.forgot_password_spinner = false
        self.$bvModal.hide('forgot-password')
        self.$toast.default('We sent email to reset your password.')
        self.clearForm()
      }).catch(() => {
        self.forgot_password_spinner = false
      })
    }
  }
};
</script>
<style src="./Login.css"></style>
