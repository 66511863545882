import { _resource } from "@/api/_http";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("banks", payload).then(response => {
      if (response) {
        context.commit("BANK_SAVED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("banks/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("BANK_UPDATED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("BANK_FETCHED", response);
      }
    });
  }
};

const mutations = {
  BANK_SAVED: (state, data) => {
    state.status = data.success;
    state.data.data.unshift(data.data)
  },
  BANK_UPDATED: (state, data) => {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id);
    state.data.data.splice(index, 1, res)

    state.status = data.success;
  },
  BANK_FETCHED(state, payload) {
    state.data = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const banks = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
