<template src="./Account.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";

import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.merchants.status,
    user: state => state.merchants.data
  }),
  actions: mapActions({
    query: "merchants/query",
    change_password_submit: "change_passwords/submit"
  })
}


export default {
  name: "Account",
  components: {
    MiniLoader
  },
  data() {
    return {
      form: {
        old_password : '',
        password : '',
        password_confirmation : ''
      },
      user_info: JSON.parse(localStorage.getItem("info")),
      error_wrong_pass: false
    };
  },
  validations: {
    form : {
        old_password : {required},
        password : {
          required,
          minLength: minLength(8)
        },
        password_confirmation : {
          sameAsPassword: sameAs("password")
        },
      }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
  },
  methods:  {
    ...container.actions,
    sendChangePassword() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.submitChangePassword()
      }
      return false;
    },
    submitChangePassword() {
      const self = this
      this.loader = true;
      self.error_wrong_pass = false

      self.change_password_submit(self.form).then(() => {
        this.loader = false;
        self.$toast.default("Password successfully changed.")
        self.reset()
        self.$bvModal.hide('modal-change-password')
      }).catch((e) => {
        this.loader = false;
        if (!e.response.data.success) {
          if (e.response.data.error_code == 'USER-007') {
            self.error_wrong_pass = true
          }
        }
      })
    },
    reset() {
      const self = this
      Object.keys(self.form).forEach(key => {
          self.form[key] = ''
      });

      self.$v.form.$reset();
    }
  }
};
</script>
<style src="./Account.css"></style>
