<template src="./Role.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";

import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

const container = {
  state: mapState({
    roles: state => state.roles.data,
    permissions: state => state.permissions.data,
    permission_new: state => state.permissions.new
  }),
  actions: mapActions({
    qry_permissions: "permissions/query",
    save_permission: "permissions/store",
    qry_roles: "roles/query",
    save_role: "roles/store",
    update_role: "roles/update",
    delete_role: "roles/delete"
  })
}

export default {
  name: "Role",
  components: {
    MiniLoader
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
    document.body.classList.add('home')
    this.getRoles();
    this.getPermissions();
  },
  data() {
    return {
      permission_loader: false,
      update_role_loader: false,
      delete_role_loader: false,
      role_loader: false,
      permission_list: [],
      permission_name: '',
      form_permission: {
        name: '',
        guard_name: 'api'
      },
      form_role: {
        name: '',
        role_alias: '',
        guard_name: 'api',
        type: 'admin'
      },
      edit: {
        id: '',
        type: '',
        name: '',
        role_alias : ''
      },
      show_permission_exist: false,
      option_type: [
        {text: 'Admin', value: 'admin'},
        {text: 'Merchant', value: 'merchant'}
      ],
      selected_delete: [],
      selected_permissions: []
    };
  },
  validations: {
    form_permission: {
      name: {required}
    },
    form_role: {
      name: {required},
      role_alias: {required},
      type:{}
    },
    edit: {
      id: {},
      type: {},
      name: {required},
      role_alias: {required}
    }
  },
  methods: {
    ...container.actions,
    getRoles() {
      const self = this
      self.qry_roles("roles").then(() => {
        self.pageloader = false
      })
    },
    getPermissions() {
      const self = this
      this.loader = false
      self.qry_permissions("permissions").then(() => {
        self.pageloader = false
        self.getPermissionList()
      })
    },
    getPermissionList() {
      const self = this
      for (var key in self.permissions) {
        self.permission_list.push(self.permissions[key].name)
      }
    },
    sendAddPermission() {
      this.$v.form_permission.$touch()
      if (!this.$v.form_permission.$invalid) {
        this.saveAddPermission()
      }

      return false
    },
    saveAddPermission() {
      const self = this

      self.permission_loader = true
      self.show_permission_exist = false

      self.save_permission(self.form_permission).then(() => {
        self.permission_loader = false
        self.$bvModal.hide('show-add-permission')
        self.$toast.default('Permission added.')

        self.permission_list.push(self.form_permission.name)
        self.permissions.push(self.permission_new.data)
      }).catch((error) => {
        self.permission_loader = false
        if (error.response.data.error_code == 'E-PERMISSION-003') {
            self.show_permission_exist = true
        } else {
            self.$bvModal.hide('show-add-permission')
        }
      })
    },
    sendAddRole() {
      this.$v.form_role.$touch()
      if (!this.$v.form_role.$invalid) {
        this.saveAddRole()
        this.getRoles()
      }

      return false
    },
    saveAddRole() {
      const self = this

      self.role_loader = true

      self.save_role(self.form_role).then(() => {
        self.role_loader = false
        self.$bvModal.hide('show-add-role')
        self.$toast.default('Role added.')

        //self.permission_list.push(self.form_role.name)
      }).catch(() => {
        self.role_loader = false
          self.$bvModal.hide('show-add-role')
      })
    },
    showUpdate(role) {
      const self = this

      self.edit.id = role.id
      self.edit.name = role.name
      self.edit.role_alias = role.role_alias
      self.edit.type = role.type
      self.selected_permissions = role.permissions.slice()

      self.$bvModal.show('show-edit-role')      
    },
    addPermission() {
      const self = this
      var index = self.permissions.findIndex(item => item.name == self.permission_name)

      var add = {
        name: self.permissions[index].name,
        id: self.permissions[index].id
      }

      var isExist = -1

      isExist = self.selected_permissions.findIndex(item => item.name == self.permission_name)

      if (isExist < 0) {        
        self.selected_permissions.push(add)
        self.permission_name = ''
      }
    },
    removeRole(id) {
      const self = this
      var index = self.selected_permissions.findIndex(item => item.id === id)
      self.selected_permissions.splice(index, 1)
    },
    sendUpdateRole() {
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updatePermissions()
      }

      return false
    },
    updatePermissions() {
      const self = this
      var permitted = self.selected_permissions.map(value => value.id)

      var payload = {
        'id': self.edit.id,
        'name': self.edit.name,
        'role_alias': self.edit.role_alias,
        'type': self.edit.type,
        'permission_id' : permitted
      }

      self.update_role_loader = true

      self.minloader = true
      self.update_role(payload).then(() => {
        self.update_role_loader = false
        self.$bvModal.hide('show-edit-role')
        self.selected.permissions = self.selected_permissions
        self.$toast.default('Role updated')
      }).catch(() => {
        self.update_role_loader = false
        self.minloader = false
      })
    },
    showDelete(role) {
      this.selected_delete = role.id
      this.$bvModal.show('show-delete-role')
    },
    deleteRole() {
      const self = this

      self.delete_role_loader = true
      self.delete_role(self.selected_delete).then(() => {
        self.delete_role_loader = false
        self.$bvModal.hide('show-delete-role');
      }).catch(() => {
        self.delete_role_loader = false
        self.$bvModal.hide('show-delete-role');
      })
    }
  }
};
</script>
<style scoped src="./Role.css"></style>
