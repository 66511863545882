import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("SCRAPES_FETCHED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("scrapper/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("DEPOSIT_UPDATED", response);
      }
    });
  },
  async match(context, payload) {
    await _resource.save("scrapper/match", payload).then(response => {
      if (response) {
        context.commit("SCRAPES_MATCHED", response);
      }
    });
  }
};

const mutations = {
  SCRAPES_FETCHED(state, payload) {
    state.data = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  DEPOSIT_UPDATED: (state, data) => {
    let res = data.data;
    var index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)

    state.status = data.success;
  },
  SCRAPES_MATCHED(state, data) {
    let res = data.data;
    var index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const scheduledscrapper = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
