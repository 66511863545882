<template src="./RemittanceBankaccounts.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue"

import { required, maxLength } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({    
    status: state => state.remittancebankaccounts.status,
    bankaccounts: state => state.remittancebankaccounts.data
  }),
  actions: mapActions({
    query: "remittancebankaccounts/query",
    save: "remittancebankaccounts/store",
    update: "remittancebankaccounts/update"
  })
}

export default {
  name: "RemittanceBankaccounts",
  components: {
    MiniLoader
  },
  data() {
    return {
      add_loader: false,
      edit_loader: false,
      form: {
        bank_name: '',
        bank_code: '',
        branch_name: '',
        branch_code: '',
        account_type: '',
        account_number: '',
        account_name: ''
      },
      edit: {
        id: '',
        bank_name: '',
        bank_code: '',
        branch_name: '',
        branch_code: '',
        account_type: '',
        account_number: '',
        account_name: ''
      }
    }
  },
  validations: {
    form: {
      bank_name: {required},
      bank_code: {required, max_length: maxLength(4)},
      branch_name: {},
      branch_code: {required, max_length: maxLength(3)},
      account_type:{required, max_length: maxLength(8)},
      account_number:{required},
      account_name:{}
    },
    edit: {
      id: {},
      bank_name: {required},
      bank_code: {required, max_length: maxLength(4)},
      branch_name: {},
      branch_code: {required, max_length: maxLength(3)},
      account_type:{required, max_length: maxLength(8)},
      account_number:{required},
      account_name:{}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted () {
    this.loader = false
    this.getBankAccounts()
  },
  methods: {
    ...container.actions,
    getBankAccounts() {
      const self = this
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page;

      self.query("remittancebankaccounts" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.bankaccounts.total;
        this.page.count = self.bankaccounts.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    submitCreate() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.saveBank()
      }

      return false
    },
    submitUpdate() {
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateBank()
      }

      return false
    },
    saveBank() {
      const self = this;
      self.add_loader = true;
      self
        .save(self.form)
        .then(() => {
            self.add_loader = false;
            self.$toast.default('Bank account added successfully.')
            self.$bvModal.hide('show-add');
            self.clearForms()
            self.getBankAccounts()
        }).catch(() => {
          self.$toast.default('An error occured while creating bank account.')
          self.add_loader = false
        })
    },
    updateBank() {
      const self = this;
      self.edit_loader = true;
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;
            self.$toast.default('Bank account updated successfully.')
            self.$bvModal.hide('show-edit');
            self.clearForms()
            self.getBankAccounts()
        }).catch(() => {
          self.$toast.default('An error occured while updating bank account.')
          self.add_loader = false
        })
    },
    showEdit(item) {
      const self = this

      self.edit.id = item.id
      self.edit.bank_name = item.bank_name
      self.edit.bank_code = item.bank_code
      self.edit.branch_name = item.branch_name
      self.edit.branch_code = item.branch_code
      self.edit.account_type = item.account_type
      self.edit.account_number = item.account_number
      self.edit.account_name = item.account_name

      self.$bvModal.show('show-edit')
    },
    clearForms() {
      const self = this
      for (var prop in self.form) {
        self.form[prop] = "";
      }

      /*for (var prop in self.edit) {
        self.edit[prop] = "";
      }*/
      self.$v.form.$reset();
      //self.$v.edit.$reset();
    },
  }
};
</script>
<style scoped src="./RemittanceBankaccounts.css"></style>
