<template src="./ScheduledScrapper.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue"
import FileUpload from "@/partials/FileUpload/FileUpload.vue";

import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({    
    status: state => state.scheduledscrapper.status,
    scrapes: state => state.scheduledscrapper.data,
    requests: state => state.requests.data,
    // request_status: state => state.requests.status,
    request: state => state.requests.request,
    // bankgrps: state => state.bankgroups.data
    merchants: state => state.merchants.lookup
  }),
  actions: mapActions({
    query: "scheduledscrapper/query",
    update: "scheduledscrapper/update",
    match: "scheduledscrapper/match",
    request_query: "requests/query",
    request1_query: "requests/get_one",
    // qry_bankgroups: "bankgroups/query"
    _getMerchants: "merchants/lookup",
  })
}

export default {
  name: "ScheduledScrapper",
  components: {
    MiniLoader,
    FileUpload
  },
  data() {
    return {
      export_loader: false,
      updating_loader: false,
      matching_loader: false,
      show_filter: false,
      edit: {
        status: '',
        reference_number: ''
      },
      matching: {
        id: '',
        // data_id: '',
        // request_id: '',
        reference_number: '',
        // deposit_slip_file: '',
        notes: ''
      },
      deposit: {
        id: '',
        client: '',
        program_name: '',
        reference_number: '',
        name: '',
        am_deposited: '',
        date_deposited: ''
      },
      show_request: false,
      proceed: false,
      invalid_reference_number: false,
      filter: {
        search_type: 'reference_number',
        search: '',
        payment_date_from: '',
        payment_date_to: '',
        read_datetime_from: '',
        read_datetime_to: '',
        is_matched: '',
        status: '',
        name: '',
        merchant_id: '',
        // bank_group_id: ''
        amount: ''
      },
      search_type: [
        {
          value:'reference_number',
          name:'Reference Number'         
        },
        {
          value:'program_name',
          name:'Account Number'
        },
        // {
        //   value:'receive_bank_name',
        //   name:'Receive Bank'
        // },
        // {
        //   value:'receive_branch_name',
        //   name:'Receive Branch'
        // },
        {
          value:'account_name',
          name:'Name'
        },
      ],
      qry: '',
      export_query: '',
      export_filename: 'Lion-Gateway[ScheduledScrapper].csv',
      selected: {},
      paymentDate: '',
      readDateTime: ''
    }
  },
  validations: {
    edit: {
      id: {},
      status: {required},
      reference_number: {}
    },
    matching: {
      id : {},
      //request_id: {required},
      reference_number: {required},
      notes: {required}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted () {
    this.loader = false

    // Reactivate the saved filter after a page change
    this.filter.merchant_id = localStorage.getItem("deposits.merchant_id")
    if (this.filter.merchant_id === null) {
      this.filter.merchant_id = ''
    }

    this.filter.status = localStorage.getItem("deposits.status")
    if (this.filter.status === null) {
      this.filter.status = ''
    }

    if (this.filter['search'] === "") {
      this.filter["search_type"] = ""
    }

    this.qry = this.buildFilter(this.filter)

    if (this.filter['search'] === "") {
      this.filter["search_type"] = "reference_number"
    }

    this.getScrapes()
    //this.getApplying()
    // this.getBankGroups()
    this.getMerchants()
  },
  methods: {
    ...container.actions,
    changeColorByMerchant(merchant_id, is_matched = false) {
      if (merchant_id == 2) {
        if (is_matched) {
          return 'darkviolet; font-weight: bold'
        }

        return 'darkviolet'
      }

      return ''
    },
    getScrapes() {
      const self = this
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page + self.qry;

      self.export_query = '/scrapper/export?' + self.qry;

      self.query("scrappers" + qry_string).then(() => {
        this.page.total = self.scrapes.total;
        this.page.count = self.scrapes.last_page
        this.loader = false
      }).catch(() => {
        this.loader = false
      })
    },
    getMerchants() {
      const self = this
      this.loader = true

      self._getMerchants("merchants").then(() => {
        this.loader = false
      })
    },
    // getBankGroups() {
    //   const self = this
    //   this.loader = true
    //
    //   self.qry_bankgroups("bank-groups/1").then(() => {
    //     this.loader = false
    //   })
    // },
    jumpToPage(page) {
      let toPage = this.page.current + page;
      if (toPage < 1) {
        toPage = 1;
      } else {
        if (toPage > this.page.count) {
          toPage = this.page.count
        }
      }

      this.page.current = toPage;

      this.getScrapes();
    },
    showMatch(item) {
      const self = this

      self.invalid_reference_number = false;

      self.matching.id = item.id;
      self.matching.data_id = item.data_id;
      self.matching.reference_number = item.reference_number

      //
      self.deposit.id = item.id
      self.deposit.merchant_id = item.merchant_id
      self.deposit.client = item.client
      self.deposit.program_name = item.program_name
      self.deposit.reference_number = item.reference_number
      self.deposit.name = item.name
      self.deposit.am_deposited = item.amount
      self.deposit.date_deposited = item.payment_date
      self.deposit.read_datetime = item.read_datetime

      // Find matching payment
      for (let prop in self.request) {
        self.request[prop] = "";
      }

      this.loader = true
      self.invalid_reference_number = false
      if (self.matching.reference_number) {
        self.request1_query("payments/" + self.matching.reference_number).then(() => {
          self.show_request = true
          this.loader = false
        }).catch(() => {
          self.invalid_reference_number = true
          self.show_request = false
          this.loader = false
        })
      } else {
        self.invalid_reference_number = true
        self.show_request = false
        this.loader = false
      }

      self.$bvModal.show('match-modal');                                                                                         
    },
    showEditForm(item) {
      const self = this

      self.invalid_reference_number = false;

      self.edit.id = item.id;
      self.edit.status = item.status;
      self.edit.reference_number = item.reference_number

      self.$bvModal.show('modal-edit');
    },
    // getApplying() {
    //   const self = this
    //   this.loader = true
    //
    //   self.request_query("request/status/applying").then(() => {
    //     this.loader = false
    //   }).catch(() => {
    //     this.loader = false
    //   })
    // },
    getPaymentRequest() {
      const self = this
      this.loader = true
      self.invalid_reference_number = false
      self.request1_query("payments/" + self.matching.reference_number).then(() => {
        self.show_request = true
        self.proceed = true
        this.loader = false
      }).catch(() => {
        self.invalid_reference_number = true
        self.show_request = false
        this.loader = false
      })
    },
    getSelectedRequest(data) {
      this.matching.request_id = data.id
    },
    sendMatch() {
      // var formUpload = this.$refs.file_upload
      this.$v.matching.$touch()
      if (!this.$v.matching.$invalid) {
        // this.saveMatch(formUpload)
        this.saveMatch()
      }

      return false
    },
    // saveMatch(formUpload) {
    saveMatch() {
      const self = this;

      let formData = new FormData()
      // formData.append("deposit_slip_file", formUpload.$refs.file.files[0]);

      formData.append("id", self.matching.id);
      //formData.append("request_id", self.matching.request_id);
      formData.append("reference_number", self.matching.reference_number);
      formData.append("notes", self.matching.notes);

      self.matching_loader = true;
      self
        .match(formData)
        .then(() => {
            self.matching_loader = false;
            self.$toast.default('Item matched successfully.')
            self.$bvModal.hide('match-modal');
            self.clearForms()
        }).catch(() => {
          //self.add_loader = false
          self.matching_loader = false
          self.invalid_reference_number = true
        })
    },
    prepareUpdateStatus() {
      this.$v.edit.$touch()
      if (!this.$v.edit.$invalid) {
        this.updateStatus()
      }

      return false
    },
    updateStatus() {
      const self = this;
      self.updating_loader = true;
      self
          .update(self.edit)
          .then(() => {
            self.updating_loader = false;
            self.$bvModal.hide('modal-edit');

            self.$toast.default('Updated successfully.')
          }).catch(() => {
            self.updating_loader = false
          })
    },
    clearForms() {
      const self = this
      for (var prop in self.matching) {
        self.matching[prop] = "";
      }

      /*for (var prop in self.edit) {
        self.edit[prop] = "";
      }*/
      self.$v.matching.$reset();
      //self.$v.edit.$reset();
    },
    setFilter() {
      const self = this

      localStorage.setItem('deposits.merchant_id', self.filter.merchant_id)
      localStorage.setItem('deposits.status', self.filter.status)

      if (this.filter['search'] === "") {
        this.filter["search_type"] = ""
      }

      self.qry = self.buildFilter(self.filter)

      if (this.filter['search'] === "") {
        this.filter["search_type"] = "reference_number"
      }

      this.page.current = 1

      self.getScrapes()
    },
    clearFilter() {
      const self = this

      self.qry = ''

      for (let key in self.filter) {
        self.filter[key] = ''
      }

      self.filter.search_type = 'reference_number'

      localStorage.setItem('deposits.merchant_id', self.filter.merchant_id)
      localStorage.setItem('deposits.status', self.filter.status)

      this.page.current = 1

      self.getScrapes()
    },
    exportData() {
      const self = this
      self.export_loader = true
      let accessToken = localStorage.getItem("token")
      var link = process.env.VUE_APP_ROOT_API + self.export_query
      var headers = {
        'responseType' : 'arraybuffer',
        'headers' : {
          'Authorization': `Bearer ${accessToken}`
        }
      }

      self.$http.get(link, headers).then(response => {
        self.export_loader = false
        self.$bvModal.hide('export-summary')

        var BOM = new Uint8Array([0xEF,0xBB,0xBF]);
        var blob = new Blob([BOM, response.data], {encoding:'UTF-8', type: 'application/vnd.ms-excel; charset=UTF-8'})
        
        //var blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
        var downloadUrl = URL.createObjectURL(blob)
        var a = document.createElement("a")
        a.href = downloadUrl

        a.download =  self.export_filename 
        
        document.body.appendChild(a)
        a.click()
      })
    },
    showView(item) {
      const self = this
      self.selected = item
      self.paymentDate = self.$moment(self.selected.payment_date).format("YYYY/MM/DD")
      self.readDateTime = self.$moment(self.selected.read_datetime).format("YYYY/MM/DD hh:mm A")
      self.$bvModal.show('view-detail')
    }
  }
};
</script>
<style scoped src="./ScheduledScrapper.css"></style>
