<template src="./Request.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import FileUpload from "@/partials/FileUpload/FileUpload.vue";

import { mapState, mapActions } from "vuex";
import {required, minValue} from "vuelidate/lib/validators";

const container = {
  state: mapState({
    status: state => state.requests.status,
    requests: state => state.requests.data,
    requests_extract: state => state.requests.extract_data,
    // bankgrps: state => state.bankgroups.data,
    merchants: state => state.merchants.lookup
  }),
  actions: mapActions({
    query: "requests/query",
    update_request: "requests/update",
    extract_request: "requests/extract",
    save_all_request: "requests/save_all",
    qry_merchants: "merchants/lookup",
    // qry_bankgroups: "bankgroups/query"
  })
}

export default {
  name: "Request",
  components: {
    MiniLoader,
    FileUpload
  },
  data() {
    return {
      export_loader: false,
      extract_loader: false,
      update_loader: false,
      import_loader: false,
      show_filter: false,
      filter: {
        search: '',
        name: '',
        status: '',
        amount: '',
        date_from: '',
        date_to: '',
        date_deposited_from: '',
        date_deposited_to: '',
        merchant_id: '',
        bank_group_id: ''
      },
      qry: '',
      update: {
        id: '',
        am_deposited: 0,
        status: '',
        notes: '',
        resend_callback: false
      },
      selected: '',
      dep_slip: '',
      export_query: '',
      export_filename: 'Lion-Gateway[Payments].csv',
      extract_fields: ['Reference Number', 'Merchant Name', 'Transaction ID', 'Name', 'Email', 'Amount Deposited', 'Update to Status'],
      csv: {
        file: ''
      },
      extract_page: {
        per_page: 10,
        total: 0,
        current: 1,
        count: 0
      },
      show_extracted: [],
      error_data: [],
      role_name: localStorage.getItem("role")
    };
  },
  validations: {
    update: {
      id: {},
      am_deposited: {required, minValue: minValue(1)},
      status: {},
      notes: {required},
      resend_callback: {}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
    document.body.classList.add('home')

    // Reactivate the saved filter after a page change
    this.filter.merchant_id = localStorage.getItem("payments.merchant_id")
    if (this.filter.merchant_id === null) {
      this.filter.merchant_id = ''
    }

    this.filter.status = localStorage.getItem("payments.status")
    if (this.filter.status === null) {
      this.filter.status = ''
    }

    this.qry = this.buildFilter(this.filter)

    this.getRequests()
    this.getMerchants()
    // this.getBankGroups()
  },
  methods: {
    ...container.actions,
    getRequests() {
      const self = this
      this.loader = true

      const qry_string = '?page=' + this.page.current + '&per_page=' + this.page.per_page + self.qry;

      self.export_query = '/requests/export?' + self.qry;

      self.query("requests" + qry_string).then(() => {
        this.loader = false
        this.page.total = self.requests.total;
        this.page.count = self.requests.last_page
      }).catch(() => {
        this.loader = false
      })
    },
    getMerchants() {
      const self = this
      this.loader = true

      self.qry_merchants("merchants").then(() => {
        this.loader = false
      })
    },
    // getBankGroups() {
    //   const self = this
    //   this.loader = true
    //
    //   self.qry_bankgroups("bank-groups/1").then(() => {
    //     this.loader = false
    //   })
    // },
    gotoList(page) {
      var start = (page == 1) ? 0 : (page -1 ) * this.extract_page.per_page;
      var end = page * this.extract_page.per_page

      this.prepareList(start, end)
      this.extract_page.current = page
    },
    setFilter() {
      const self = this

      self.qry = self.buildFilter(self.filter)

      localStorage.setItem('payments.merchant_id', self.filter.merchant_id)
      localStorage.setItem('payments.status', self.filter.status)

      this.page.current = 1

      self.getRequests()
    },
    clearFilter() {
      const self = this

      self.qry = ''

      for (let key in self.filter) {
        self.filter[key] = ''
      }

      localStorage.setItem('payments.merchant_id', self.filter.merchant_id)
      localStorage.setItem('payments.status', self.filter.status)

      this.page.current = 1

      self.getRequests()
    },
    setSelected(item) {
      this.selected = item
      this.update.id = item.id
      this.update.am_deposited = item.am_deposited
      this.update.status = item.status
      this.update.notes = item.notes;
      this.$bvModal.show('payment-summary');
    },
    setDepositSlip(item) {
      this.dep_slip = item
      this.$bvModal.show('show-deposit-slip');
    },
    exportData() {
      const self = this
      self.export_loader = true
      let accessToken = localStorage.getItem("token")
      var link = process.env.VUE_APP_ROOT_API + self.export_query
      var headers = {
        'responseType' : 'arraybuffer',
        'headers' : {
          'Authorization': `Bearer ${accessToken}`
        }
      }

      self.$http.get(link, headers).then(response => {
        self.export_loader = false
        self.$bvModal.hide('export-summary')

        var BOM = new Uint8Array([0xEF,0xBB,0xBF]);
        var blob = new Blob([BOM, response.data], {encoding:'UTF-8', type: 'application/vnd.ms-excel; charset=UTF-8'})
        
        //var blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
        var downloadUrl = URL.createObjectURL(blob)
        var a = document.createElement("a")
        a.href = downloadUrl

        a.download =  self.export_filename 
        
        document.body.appendChild(a)
        a.click()
      })
    },
    sendUpdate() {
      this.$v.update.$touch();
      if (!this.$v.update.$invalid) {
        this.updateRequest()
      }

      return false;
    },
    updateRequest() {
      const self = this
      self.update_loader = true

      self.update_request(self.update).then(() => {
        self.update_loader = false
        self.$bvModal.hide('payment-summary');
        self.$toast.default('Payment updated.')
      }).catch(() => {
        self.update_loader = false
      }) 
    },
    sendExtract() {
      var formUpload = this.$refs.file_upload
      if (formUpload.validate()) {
        this.submitExtract(formUpload)
      }
      return false
    },
    submitExtract(formUpload) {
      let self = this
      let formData = new FormData()
      formData.append("import_file", formUpload.$refs.file.files[0])

      self.import_loader = true      
      
      self.extract_request(formData).then(() => {
        self.extract_page.count = Math.ceil(
          self.requests_extract.data.length / self.extract_page.per_page
        );
        self.prepareList(0, self.extract_page.per_page)
        self.$bvModal.hide('show-import')
        self.$bvModal.show('show-extract')
        self.import_loader = false    
      }).catch((e) => {
        self.error_data = e.response.data.data
        self.import_loader = false
      })
    },
    prepareList(start, end) {
      const self = this
      self.show_extracted = self.requests_extract.data.slice(start, end)
    },
    saveAllUpdate() {
      const self = this
      var payload = {
        data : JSON.stringify(self.requests_extract.data)
      }

      self.extract_loader = true

      self.save_all_request(payload).then(() => {
        self.$bvModal.hide('show-extract')
        self.extract_loader = false
        self.clearFilter()
      }).catch(() => {
        self.extract_loader = false
      })
    },
  }
};
</script>
<style src="./Request.css"></style>
