import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("withdrawal", payload).then(response => {
      if (response) {
        context.commit("WITHDRAWAL_SAVED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("withdrawal/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("WITHDRAWAL_UPDATED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("WITHDRAWAL_FETCHED", response);
      }
    });
  },
  async complete({ commit }, payload) {
    await _resource.save("withdrawal/complete", payload).then(response => {
      if (response) {
        commit("WITHDRAWAL_COMPLETE", response);
      }
    });
  },
  async extract(context, payload, state) {
    await _resource.save("withdrawals/import", payload).then(response => {
      state
      if (response) {
        context.commit("WITHDRAWAL_EXTRACTED", response);
      }
    });
  },
  async save_all(context, payload) {
    await _resource.save("withdrawals/import/save-all", payload).then(response => {
      if (response) {
        context.commit("WITHDRAWAL_SAVE_ALL", response);
      }
    });
  }
};

const mutations = {
  WITHDRAWAL_SAVED: (state, data) => {
    state.status = data.success;
    state.data.unshift(data.data)
  },
  WITHDRAWAL_UPDATED: (state, data) => {
    let res = data.data;
    var index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)

    state.status = data.success;
  },
  WITHDRAWAL_FETCHED(state, payload) {
    state.data = payload.data;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  WITHDRAWAL_COMPLETE: (state, data) => {
    let res = data;
    var result = res.data.ids;
    
    state.status = res.success;
    for (let i = 0; i <= result.length; i++) {
      var index = state.data.data.findIndex(item => item.id === result[i])
      if(state.data.data[index] != undefined) {
        state.data.data[index].status = 'completed';
      }
    }
  },
  WITHDRAWAL_EXTRACTED: (state, data) => {
    let res = data;
    state.extract_data = res.data;
    state.status = res.success;
  },
  WITHDRAWAL_SAVE_ALL: (state, data) => {
    state.status = data.success;
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const withdrawals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
