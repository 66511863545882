import {_resource} from "@/api/_resource";

const state = {
  status: false,
  data: [],
  lookup: [],
  users: [],
  user: {},
  bankgroups: [],
  banks: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("merchant", payload).then(response => {
      if (response) {
        context.commit("MERCHANT_SAVED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("MERCHANT_FETCHED", response);
      }
    });
  },
  async lookup(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("MERCHANT_LOOKUP", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("merchant/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("MERCHANT_UPDATED", response);
      }
    });
  },
  async delete(context, id) {
    await _resource.destroy("merchant/" + id).then(response => {
      if (response) {
        context.commit("MERCHANT_DELETED", response);
      }
    });
  },
  async add_bankgroup(context, payload) {
    await _resource.save("merchants/" + payload.id + "/bankgroups", payload).then(response => {
      if (response) {
        context.commit("BANKGROUP_SAVED", response);
      }
    });
  },
  async get_users(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("USERS_FETCHED", response);
      }
    });
  },
  async get_bankgroups(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("BANKGROUPS_FETCHED", response);
      }
    });
  },
  async get_banks(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("BANKS_FETCHED", response);
      }
    });
  },
  async delete_bankgroup(context, query) {
    await _resource.destroy(query).then(response => {
      if (response) {
        context.commit("BANKGROUP_DELETED", response);
      }
    });
  },
  async create_token(context, payload) {
    await _resource.save("token", payload).then(response => {
      if (response) {
        context.commit("MERCHANT_TOKEN_SAVED", response);
      }
    });
  },
  async delete_token(context, payload) {
    await _resource.destroy('token/' + payload.id).then(response => {
      if (response) {
        context.commit("MERCHANT_TOKEN_DELETED", response);
      }
    });
  }
};

const mutations = {
  MERCHANT_SAVED: (state, data) => {
    state.status = data.success;
    state.data.unshift(data.data)
  },
  MERCHANT_FETCHED(state, payload) {
    state.data = payload.data;
    state.status = payload.success; 
  },
  MERCHANT_LOOKUP(state, payload) {
    state.lookup = payload.data;
    state.status = payload.success;
  },
  BANKGROUP_SAVED: (state, data) => {
    state.status = data.success;
    state.bankgroups.unshift(data.data)
  },
  USERS_FETCHED(state, payload) {
    state.users = payload.data;
    state.status = payload.success;
  },
  BANKGROUPS_FETCHED(state, payload) {
    state.bankgroups = payload.data;
    state.status = payload.success;
  },
  BANKS_FETCHED(state, payload) {
    state.banks = payload.data;
    state.status = payload.success;
  },
  BANKGROUP_DELETED(state, payload) {
    state.status = payload.success;
  },
  MERCHANT_UPDATED: (state, data) => {
    const res = data.data;
    state.user = res
    const index = state.data.data.findIndex(item => item.id == res.id)
    if (index > -1) {
      state.data.data.splice(index, 1, res)
    }

    state.status = data.success;
  },
  MERCHANT_DELETED(state, data) {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id);
    state.data.data.splice(index, 1)
    state.status = data.success;
  },
  MERCHANT_TOKEN_DELETED: (state, data) => {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)
    state.status = data.success;
  },
  MERCHANT_TOKEN_SAVED: (state, data) => {
    let res = data.data;
    const index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)
    state.status = data.success;
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const merchants = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
